import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import GeoAutoComplete from "./GeoAutoComplete";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const initialSchema = {
  placeId: "",
  name: "",
  formattedAddress: "",
  streetAddress: "",
  postalCode: "",
  locality: "",
  country: "",
  lat: "",
  lng: "",
  url: "",
};

const validationSchema = (t) =>
  Yup.object().shape({
    placeId: Yup.string(t("visitGeoForm.hints.placeId")).required(
      t("visitGeoForm.errors.placeId")
    ),
    name: Yup.string(t("visitGeoForm.hints.name")).required(
      t("visitGeoForm.errors.name")
    ),
    formattedAddress: Yup.string(
      t("visitGeoForm.hints.formattedAddress")
    ).required(t("visitGeoForm.errors.formattedAddress")),
    streetAddress: Yup.string(t("visitGeoForm.hints.streetAddress")).required(
      t("visitGeoForm.errors.streetAddress")
    ),
    postalCode: Yup.string(t("visitGeoForm.hints.postalCode")).default(""),
    locality: Yup.string(t("visitGeoForm.hints.locality")).required(
      t("visitGeoForm.errors.locality")
    ),
    country: Yup.string(t("visitGeoForm.hints.country")).required(
      t("visitGeoForm.errors.country")
    ),
    lat: Yup.number(t("visitGeoForm.hints.lat")).required(
      t("visitGeoForm.errors.lat")
    ),
    lng: Yup.number(t("visitGeoForm.hints.lng")).required(
      t("visitGeoForm.errors.lng")
    ),
    url: Yup.string(t("visitGeoForm.hints.url"))
      .url("visitGeoForm.errors.urlInvalid")
      .required(t("visitGeoForm.errors.url")),
  });

const textFieldConfig = (
  id,
  label,
  values,
  touched,
  errors,
  handleChange,
  handleBlur
) => {
  return {
    id,
    name: id,
    label: label,
    value: values[id] || "",
    fullWidth: true,
    variant: "outlined",
    margin: "dense",
    helperText: touched[id] ? errors[id] : "",
    error: touched[id] && Boolean(errors[id]),
    onChange: handleChange,
    onBlur: handleBlur,
  };
};

const VisitGeoForm = forwardRef(
  ({ visitGeo, onSubmit, accordionProp }, ref) => {
    //
    if (!accordionProp) {
      accordionProp = {
        disabled: false,
        expanded: false,
      };
    }

    const [t] = useTranslation();

    // delete unused properties
    const { createdAt, updatedAt, ...visitGeoN } = visitGeo || {};
    const oldPlaceId = visitGeo?.placeId;

    const formRef = React.useRef();

    const [accordion, setAccordion] = useState({
      disabled: accordionProp.disabled,
      expanded: accordionProp.expanded,
    });

    const handleAutoComplete = (visitGeo) => {
      if (visitGeo) formRef.current.setValues({ ...visitGeo });
      setAccordion({ expanded: true });
    };

    // used to submit form from parent component
    useImperativeHandle(
      ref,
      () => ({
        handleOutsideSubmit: async () => {
          const formik = formRef.current;
          try {
            await validationSchema(t).validate(formik.values);

            return formik.values;
          } catch (error) {
            formik.submitForm();
            setAccordion({ expanded: true });
            return undefined;
          }
        },
      }),
      []
    );

    return (
      <>
        <Formik
          initialValues={_.isEmpty(visitGeoN) ? initialSchema : visitGeoN}
          validationSchema={validationSchema(t)}
          onSubmit={(values) => onSubmit && onSubmit(oldPlaceId, values)}
          innerRef={formRef}
          //enableReinitialize
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            initialValues,
            values,
            handleSubmit,
            validateForm,
          }) => {
            return (
              <>
                <Accordion
                  expanded={accordion.expanded}
                  onChange={(e, expanded) => {
                    if (e.target.tagName !== "INPUT")
                      setAccordion({ expanded });
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="visitgeo-accordion-control"
                    id="visitgeo-accordion"
                  >
                    <div style={{ flexGrow: 1 }}>
                      <GeoAutoComplete onPlaceSelect={handleAutoComplete} />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Form>
                      <TextField
                        {...textFieldConfig(
                          "placeId",
                          t("visitGeoForm.labels.placeId"),
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "name",
                          t("visitGeoForm.labels.name"),
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "formattedAddress",
                          t("visitGeoForm.labels.formattedAddress"),
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "streetAddress",
                          t("visitGeoForm.labels.streetAddress"),
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "postalCode",
                          t("visitGeoForm.labels.postalCode"),
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "locality",
                          t("visitGeoForm.labels.locality"),

                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "country",
                          t("visitGeoForm.labels.country"),

                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "lat",
                          t("visitGeoForm.labels.lat"),

                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "lng",
                          t("visitGeoForm.labels.lng"),

                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                      <TextField
                        {...textFieldConfig(
                          "url",
                          t("visitGeoForm.labels.url"),

                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur
                        )}
                      />
                    </Form>
                  </AccordionDetails>
                </Accordion>
                {!accordionProp.disabled && (
                  <Button
                    onClick={handleSubmit}
                    fullWidth
                    variant="contained"
                    color="primary"
                    //disabled={!isValid}
                  >
                    {t("visitGeoForm.labels.submitButton")}
                  </Button>
                )}
              </>
            );
          }}
        </Formik>
      </>
    );
  }
);

export default VisitGeoForm;
