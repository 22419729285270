import React from "react";
import { authService } from "../../services/auth/authService";
import { grantAccess } from "../../roles/accessControl";
import { actions } from "../../roles/constants";

const ProtectedComponent = ({
  component: Component,
  protectedComponent,
  ...rest
}) => {
  const render = () => {
    const user = authService.getCurrentUser();

    if (!protectedComponent) return <Component {...rest} />;

    if (!user) return null;

    const permission = grantAccess(
      actions.ACCESS,
      protectedComponent,
      user.roleName
    );

    if (permission) return <Component {...rest} />;
    else return null;
  };

  return render();
};

export default ProtectedComponent;
