import React from "react";
import { Button, TextField } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import VisitGeoForm from "./VisitGeoForm";
import { visitService } from "../../services/Visits/visitService";

const initialSchema = {
  title: "",
  subtitle: "",
  description: "",
  categoryId: "",
  modelUrl: "",
  img: "",
};

const validationSchema = (t) =>
  Yup.object().shape({
    title: Yup.string(t("visitForm.hints.title")).required(
      t("visitForm.errors.title")
    ),
    subtitle: Yup.string(t("visitForm.hints.subtitle")).default("").nullable(),
    description: Yup.string(t("visitForm.hints.description"))
      .default("")
      .nullable(),
    categoryId: Yup.string(t("visitForm.hints.category")).required(
      t("visitForm.errors.category")
    ),
    modelUrl: Yup.string(t("visitForm.hints.modelUrl"))
      .url(t("visitForm.errors.modelInvalid"))
      .required(t("visitForm.errors.modelUrl")),
    ///.test("testUrl", t("visitForm.errors.modelErroned"), async (value) => {
    ///  try {
    ///    // check if is a valid url
    ///    await Yup.string()
    ///      .url(t("visitForm.errors.modelInvalid"))
    ///      .validate(value);
    ///
    ///    await visitService.isValidModel(value);
    ///    return true;
    ///  } catch (error) {
    ///    return false;
    ///  }
    //})
    img: Yup.string(t("visitForm.hints.img"))
      .url(t("visitForm.errors.img"))
      .default(""),
  });

const textFieldConfig = (
  id,
  label,
  values,
  touched,
  errors,
  handleChange,
  handleBlur
) => {
  return {
    id,
    name: id,
    label: label,
    value: values[id],
    fullWidth: true,
    variant: "outlined",
    margin: "dense",
    helperText: touched[id] ? errors[id] : "",
    error: touched[id] && Boolean(errors[id]),
    onChange: handleChange,
    onBlur: handleBlur,
  };
};

export default function VisitForm({ visit, onSubmit }) {
  //
  const visitGeoFormRef = React.useRef(null);
  const [t] = useTranslation();

  // delete unused properties
  const { createdAt, updatedAt, VisitGeo, ...visitN } = visit || {};
  const oldVisitId = visit?.id;
  const oldPlaceId = VisitGeo?.placeId;

  const handleSubmitForms = async (visitValues) => {
    // get values from visitGeoForm
    const visitGeoValues = await visitGeoFormRef.current.handleOutsideSubmit();
    if (visitValues === undefined || visitGeoValues === undefined) {
      return;
    }

    onSubmit(
      oldVisitId,
      { ...visitValues, placeId: visitGeoValues.placeId },
      oldPlaceId,
      visitGeoValues
    );
  };

  return (
    <>
      <Formik
        initialValues={_.isEmpty(visitN) ? initialSchema : visitN}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          handleSubmitForms(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <TextField
                {...textFieldConfig(
                  "title",
                  t("visitForm.labels.title"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
              />
              <TextField
                {...textFieldConfig(
                  "subtitle",
                  t("visitForm.labels.subtitle"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
              />
              <TextField
                {...textFieldConfig(
                  "description",
                  t("visitForm.labels.description"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                multiline
                rows={4}
              />
              <TextField
                {...textFieldConfig(
                  "categoryId",
                  t("visitForm.labels.category"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option value=""></option>
                {[1, 2, 3, 4].map((category, index) => (
                  <option key={index} value={category}>
                    {t(`categories.category${category}`)}
                  </option>
                ))}
              </TextField>
              <TextField
                {...textFieldConfig(
                  "modelUrl",
                  t("visitForm.labels.modelUrl"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
              />
              <TextField
                {...textFieldConfig(
                  "img",
                  t("visitForm.labels.img"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
              />
              {/* VisitGeo */}
            </Form>
            <VisitGeoForm
              visitGeo={VisitGeo}
              //onAction={currentFormAction}
              //onSubmit={handleSubmitVisitGeoForm}
              accordionProp={{
                disabled: true,
                expanded: false,
              }}
              ref={visitGeoFormRef}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              {t("visitForm.labels.submitButton")}
            </Button>
          </>
        )}
      </Formik>
    </>
  );
}
