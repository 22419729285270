import jwtDecode from "jwt-decode";
import { httpService } from "../httpService";
import { tokenService } from "./tokenService";

export default class AuthService {
  authenticate = async (inputs) => {
    const { data: token } = await httpService.post("auth/login", inputs);

    tokenService.setToken(token);
  };

  register = async (user) => {
    return await httpService.post("auth/register", user);
  };

  logout = () => {
    tokenService.removeToken();
  };

  getCurrentUser = () => {
    try {
      const token = tokenService.getToken();
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  };
}

export const authService = new AuthService();
