import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { toast } from "material-react-toastify";

import XDataGrid from "./XDataGrid";
import { Create, Delete, Language, Assignment } from "@material-ui/icons";

const columns = (onUpdate, onDelete) => {
  return [
    { field: "index", headerName: "#", type: "number", flex: 0.3 },
    {
      field: "placeId",
      headerName: "Place ID",
      type: "string",
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <IconButton
            aria-label="place-id"
            onClick={() => {
              navigator.clipboard.writeText(value);
              toast.dark("Place ID, copied!");
            }}
          >
            <Assignment />
          </IconButton>
        </Tooltip>
      ),
      flex: 0.4,
      sortable: false,
    },
    { field: "name", headerName: "Name", type: "string", flex: 1 },
    { field: "streetAddress", headerName: "Street", type: "string", flex: 1 },
    { field: "postalCode", headerName: "Postal", type: "number", flex: 0.5 },
    { field: "locality", headerName: "Locality", type: "string", flex: 0.6 },
    { field: "country", headerName: "Country", type: "string", flex: 0.6 },
    {
      field: "lat",
      headerName: "Latitude",
      type: "number",
      flex: 0.4,
      sortable: false,
    },
    {
      field: "lng",
      headerName: "Longtitude",
      type: "number",
      flex: 0.4,
      sortable: false,
    },
    {
      field: "url",
      headerName: "URL",
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <IconButton
            aria-label="map-url"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            <Language />
          </IconButton>
        </Tooltip>
      ),
      flex: 0.3,
      sortable: false,
    },
    {
      field: "update",
      headerName: " ",
      renderCell: (params) => (
        <IconButton onClick={() => onUpdate(visitGeoWrapper(params.row))}>
          <Create color="primary" />
        </IconButton>
      ),
      flex: 0.3,
      sortable: false,
    },
    {
      field: "delete",
      headerName: " ",
      renderCell: (params) => (
        <IconButton onClick={() => onDelete(params.row.placeId)}>
          <Delete color="error" />
        </IconButton>
      ),
      flex: 0.3,
      sortable: false,
    },
  ];
};

const visitGeoWrapper = ({
  country,
  formattedAddress,
  lat,
  lng,
  locality,
  name,
  placeId,
  postalCode,
  streetAddress,
  url,
}) => {
  return {
    country,
    formattedAddress,
    lat,
    lng,
    locality,
    name,
    placeId,
    postalCode,
    streetAddress,
    url,
  };
};

const XVisitsGeoTable = (props) => {
  const { visitsGeo, onDelete, onUpdate, onAdd, onReload, loading } = props;

  const rowsV = visitsGeo.map((value, index) => ({
    ...value,
    id: index,
    index,
    update: "",
    delete: "",
  }));

  return (
    <XDataGrid
      rows={rowsV}
      colums={columns(onUpdate, onDelete)}
      onAdd={onAdd}
      onReload={onReload}
      loading={loading}
    />
  );
};

export default XVisitsGeoTable;
