import React from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { paths } from "../Routes/paths";
import { authService } from "../../services/auth/authService";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { ExitToApp } from "@material-ui/icons";

const ProfileDrawer = ({ pathname }) => {
  const { t } = useTranslation();
  const text = t("mainDrawer.profile");

  const handleLogoutButton = () => {
    authService.logout();
    window.location = "/";
  };

  return (
    <>
      <ListItem
        component={Link}
        to={paths.ME}
        button
        key={text}
        selected={paths.ME === pathname}
      >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
      <ListItem
        component={Link}
        button
        key={t("topBar.logout")}
        onClick={handleLogoutButton}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary={t("topBar.logout")} />
      </ListItem>
    </>
  );
};

export default ProfileDrawer;
