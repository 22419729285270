import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { authService } from "../../services/auth/authService";
import VisibleVisitIcon from "../Home/VisitCard/VisibleVisitIcon";
import DoneIcon from "@material-ui/icons/Done";
import { paths } from "../Routes/paths";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: {
    marginBottom: theme.spacing(1.5),
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  profiles: {
    display: "flex",
    //justifyContent: "space-between",
  },
  profileItem: {
    margin: theme.spacing(1),
  },
}));

const initialSchema = {
  fullName: "",
  mail: "",
  password: "",
};

const validationSchema = (t) =>
  Yup.object().shape({
    fullName: Yup.string(t("registerForm.hints.fullName"))
      .required(t("registerForm.errors.fullName"))
      .test("notEmpty", t("registerForm.errors.fullNameInvalid"), (value) => {
        if (!value) return false;

        // empty
        if (value.trim().length === 0) return false;

        // no numbers
        return !value.match(/[0-9]/g);
      }),
    mail: Yup.string(t("registerForm.hints.mail"))
      .email(t("registerForm.errors.mailInvalid"))
      .required(t("registerForm.errors.mail")),
    password: Yup.string(t("registerForm.hints.password"))
      .required(t("registerForm.errors.password"))
      .test("notEmpty", t("registerForm.errors.password"), (value) => {
        return !(value && value.trim().length === 0);
      }),
  });

const textFieldConfig = (
  id,
  label,
  values,
  touched,
  errors,
  handleChange,
  handleBlur
) => {
  return {
    id,
    name: id,
    label: label,
    value: values[id],
    fullWidth: true,
    variant: "outlined",
    margin: "dense",
    helperText: touched[id] ? errors[id] : "",
    error: touched[id] && Boolean(errors[id]),
    onChange: handleChange,
    onBlur: handleBlur,
  };
};

const RegisterForm = ({ location }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [t] = useTranslation();
  const history = useHistory();

  const profiles = [
    { roleName: t("roles.visitor"), roleValue: 2 },
    { roleName: t("roles.guide_free"), roleValue: 4 },
    //{ roleName: t("roles.pro"), roleValue: 6 },
  ];

  const redirect = (to) => {
    const { state } = location;

    window.location = state ? state.from.pathname : to;
  };

  const handleSubmitButton = async (values) => {
    if (!currentRole) {
      setError(true);
      return;
    }
    try {
      setLoading(true);
      setError(false);

      const { fullName, mail, password } = values;
      const names = fullName.split(" ");
      const firstName = names.slice(0, -1).join(" ") || "";
      const lastName = names.slice(-1).join(" ");

      //console.log("F", firstName, "L", lastName);
      // create a user
      await authService.register({
        firstName,
        lastName,
        mail,
        password,
        roleId: currentRole,
      });

      // login
      //await authService.authenticate({ mail, password });
      history.push("/2fa", { mail, password, from: location });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const preventChipDelete = () => {
    setCurrentRole(null);
  };

  if (authService.getCurrentUser()) return <Redirect to="/" />;

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h4" color="primary" className={classes.title}>
          {t("registerForm.title")}
        </Typography>
        {error && (
          <Alert severity="error" onClick={() => setError(false)}>
            {!currentRole
              ? t("registerForm.roleSelectError")
              : t("registerForm.authFailed")}
          </Alert>
        )}
        <Formik
          initialValues={initialSchema}
          validationSchema={validationSchema(t)}
          onSubmit={handleSubmitButton}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.profiles}>
                {profiles.map((profile) => {
                  const { roleName, roleValue } = profile;
                  const checkRole = roleValue === currentRole;
                  return (
                    <Chip
                      key={roleName}
                      label={roleName}
                      onClick={() => {
                        setCurrentRole(roleValue);
                      }}
                      onDelete={checkRole ? preventChipDelete : null}
                      deleteIcon={checkRole ? <DoneIcon /> : null}
                      color="primary"
                      variant={checkRole ? "default" : "outlined"}
                      className={classes.profileItem}
                    />
                  );
                })}
              </div>

              <TextField
                {...textFieldConfig(
                  "fullName",
                  t("registerForm.labels.fullName"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                className={classes.input}
              />
              <TextField
                {...textFieldConfig(
                  "mail",
                  t("registerForm.labels.mail"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                className={classes.input}
              />

              <TextField
                {...textFieldConfig(
                  "password",
                  t("registerForm.labels.password"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        <VisibleVisitIcon isVisible={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.input}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.input}
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  t("registerForm.button")
                )}
              </Button>
            </Form>
          )}
        </Formik>
        <Typography>
          {t("registerForm.alreadyMember")}
          <Link to={paths.LOGIN}>{t("loginForm.button")}</Link>
        </Typography>
      </div>
    </div>
  );
};

export default RegisterForm;
