import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../Routes/paths";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5),
  },
}));

const Unauthorized = () => {
  const classes = useStyle();
  const [t] = useTranslation();
  return (
    <div className={classes.root}>
      <Typography variant="h1" color="textPrimary" gutterBottom>
        401
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        {t("content.errorPage.unauthorizedPage")}
      </Typography>
      <Link to={paths.HOME}>{t("content.homePage.redirectHome")}</Link>
    </div>
  );
};

export default Unauthorized;
