import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { mailService } from "../../services/mailService";
import Alert from "@material-ui/lab/Alert";
import { Redirect } from "react-router";
import { authService } from "../../services/auth/authService";
import { useTranslation } from "react-i18next";
import { paths } from "../Routes/paths";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    maxWidth: 400,
    alignSelf: "center",
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(1.5),
  },
}));

const initialSchema = {
  code: "",
};
const validationSchema = (t) =>
  Yup.object().shape({
    code: Yup.string(t("twoFactorForm.hints.code"))
      .length(6, t("twoFactorForm.errors.codeLength"))
      .matches(/^\d+$/, t("twoFactorForm.errors.codeRegex"))
      .required(t("twoFactorForm.errors.code")),
  });

const textFieldConfig = (
  id,
  label,
  values,
  touched,
  errors,
  handleChange,
  handleBlur
) => {
  return {
    id,
    name: id,
    label: label,
    value: values[id],
    fullWidth: true,
    variant: "outlined",
    margin: "dense",
    helperText: touched[id] ? errors[id] : "",
    error: touched[id] && Boolean(errors[id]),
    onChange: handleChange,
    onBlur: handleBlur,
  };
};

const Auth2FaForm = ({ location }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newTry, setNewTry] = useState(true);

  const errMessage = "Unxpected error";
  const { mail, password } = location.state || {};
  //console.log(location);
  const redirect = (to) => {
    const { state } = location;
    const path = state && state?.from?.pathname;
    window.location = path ? path : to;
  };

  const handleSubmitButton = async (values) => {
    setError(null);

    const data = { ...values, to: mail };
    try {
      setLoading(true);
      const result = await mailService.verifyCode(data);

      // user is valid now, login again
      await authService.authenticate({ mail, password });
      redirect("/");
      //console.log(result);
    } catch (error) {
      const err = error?.response?.data?.error;
      if (err && err === "Incorrect code") {
        setError("Incorrect code");
        return;
      }

      console.log(error);
      setError(errMessage);
    } finally {
      setLoading(false);
      setNewTry(true);
    }
  };

  const handleNewCode = async () => {
    if (!newTry) return;

    try {
      await mailService.sendVerification(mail);
      setNewTry(false);
    } catch (error) {
      console.log(error.response);
      setError(errMessage);
    }
  };

  if (!mail) {
    return <Redirect to={paths.LOGIN} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h5" color="primary" className={classes.title}>
          {t("twoFactorForm.title")}
        </Typography>
        <Typography variant="subtitle2">
          {t("twoFactorForm.subtitle")}
        </Typography>
        {error && (
          <Alert severity="error" onClick={() => setError(null)}>
            {error}
          </Alert>
        )}
        <Formik
          initialValues={initialSchema}
          validationSchema={validationSchema(t)}
          onSubmit={handleSubmitButton}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                {...textFieldConfig(
                  "code",
                  t("twoFactorForm.labels.code"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                placeholder={t("twoFactorForm.hints.code")}
                className={classes.input}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.input}
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  t("twoFactorForm.button")
                )}
              </Button>
            </Form>
          )}
        </Formik>

        <Typography>
          {t("twoFactorForm.codeReceive")}
          <Link component={"button"} onClick={handleNewCode}>
            {t("twoFactorForm.resendCode")}
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default Auth2FaForm;
