import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { userService } from "../../services/userService";
import { authService } from "../../services/auth/authService";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  infos: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [user, setUser] = useState(undefined);
  const currentUser = authService.getCurrentUser();

  const params = useParams();
  const { userId } = params;
  console.log(userId);

  const handleGetUser = async () => {
    try {
      const fetchUserId = userId ? userId : currentUser.userId;
      const result = await userService.getUserById(fetchUserId);
      const user = result.data.user;
      setUser(user);
      console.log(user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    currentUser && handleGetUser();
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <Box className={classes.root} p={3}>
      <Typography variant="h4">{t("profile.title")}</Typography>
      {user && (
        <div className={classes.infos}>
          <Typography variant="h5">{`${user.firstName} ${user.lastName}`}</Typography>
          <Typography variant="h5">{user.mail}</Typography>
        </div>
      )}
    </Box>
  );
};

export default Profile;
