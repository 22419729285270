import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import XVisitsGeoTable from "./XVisitsGeoTable";
import ModalForm from "../Admin/ModalForm";
import VisitGeoForm from "../Admin/VisitGeoForm";

import { visitGeoService } from "../../services/Visits/visitGeoService";
import { toast } from "material-react-toastify";

export default function XVisitsGeoManager() {
  const [visitsGeo, setVisitsGeo] = useState([]);
  //const [dataLoading, setDataLoading] = useState(true);
  const [modalFormState, setModalFormState] = useState(false);
  const [currentVisitGeo, setCurrentVisitGeo] = useState(undefined);
  const [currentFormAction, setCurrentFormAction] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    // fetch only if modal is closed
    !modalFormState && loadVisitsGeo();
  }, []);

  const loadVisitsGeo = async () => {
    try {
      setLoading(true);
      const result = await visitGeoService.getVisitsGeo();
      setVisitsGeo(result.data.visitsGeo);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddVisitGeo = () => {
    setCurrentVisitGeo(undefined);
    setCurrentFormAction("add");
    handleOpenModalForm();
  };

  const handleUpdateVisitGeo = (visitGeo) => {
    setCurrentVisitGeo(visitGeo);
    setCurrentFormAction("update");
    handleOpenModalForm();
  };

  const handleOnSubmitForm = (placeId, visitGeo) => {
    if (currentFormAction === "add") {
      fetchCreateVisitGeo(visitGeo);
    } else if (currentFormAction === "update") {
      fetchUpdateVisitGeo(placeId, visitGeo);
    }
  };

  const fetchCreateVisitGeo = async (visitGeo) => {
    try {
      await visitGeoService.createVisitGeo(visitGeo);
      toast.success(t("toasts.addOk"));
      handleCloseModalForm();
    } catch (err) {
      err.response && toast.error(err.response.data.error);
      err.message && toast.error(err.message);
    }
  };

  const fetchUpdateVisitGeo = async (placeId, visitGeo) => {
    try {
      await visitGeoService.updateVisitGeo(placeId, visitGeo);
      toast.success(t("toasts.updateOk"));
      handleCloseModalForm();
    } catch (err) {
      err.response && toast.error(err.response.data.error);
      err.message && toast.error(err.message);
    }
  };

  const fetchDeleteVisitGeo = async (placeId) => {
    const oldV = visitsGeo;
    const newV = oldV.filter((visitGeo) => visitGeo.placeId !== placeId);
    setVisitsGeo(newV);

    try {
      await visitGeoService.deleteVisitGeo(placeId);
      toast.success(t("toasts.deleteOk"));
    } catch (err) {
      err.message && toast.error(err.message);
      setVisitsGeo(oldV);
    }
  };

  const handleOpenModalForm = () => {
    setModalFormState(true);
  };

  const handleCloseModalForm = () => {
    setModalFormState(false);
  };

  return (
    <Box p={3}>
      {/* Modal form to add or update */}
      <ModalForm
        isOpen={modalFormState}
        handleClose={handleCloseModalForm}
        title={
          currentFormAction === "add"
            ? t("visitsGeoManager.modalAddTitle")
            : t("visitsGeoManager.modalUpdateTitle")
        }
      >
        {modalFormState ? (
          <VisitGeoForm
            visitGeo={currentVisitGeo}
            onAction={currentFormAction} // to edit ModalTitle [Add or Update]
            onSubmit={handleOnSubmitForm}
            accordionProp={{ disabled: false, expanded: true }}
          />
        ) : (
          <></>
        )}
      </ModalForm>
      <Typography variant="h4" gutterBottom>
        {t("visitsGeoManager.title")}
      </Typography>

      <XVisitsGeoTable
        visitsGeo={visitsGeo}
        onDelete={fetchDeleteVisitGeo}
        onUpdate={handleUpdateVisitGeo}
        onAdd={handleAddVisitGeo}
        onReload={loadVisitsGeo}
        loading={loading}
      />
    </Box>
  );
}
