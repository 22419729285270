import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, matchPath } from "react-router";

import LayoutVisits from "./LayoutVisits";
import Map from "../Map/Map";
import SwitchItem from "./SwitchItem";
import ModalVisitCard from "../VisitCard/ModalVisitCard";
import SearchBox from "../SearchBox";

// api fetch
import { visitService } from "../../../services/Visits/visitService";
// icons
import AppsIcon from "@material-ui/icons/Apps";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";

import { paths } from "../../Routes/paths";
import visitDemo from "../../../services/Visits/fakeDatabase/visitDemo.json";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
  },
  tabs: {
    justifyContent: "flex-end",
    marginLeft: "auto",
    maxHeight: 45,
    minHeight: "auto",

    "& .MuiTab-root": {
      minWidth: "auto",
      minHeight: "auto",
    },
  },
  scroller: {
    width: "auto",
    flex: "none",
  },
  headContainer: {
    //overflow: "visible",
    //maxWidth: 35,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

const getPathWithoutParams = (path, param) => {
  const basePath = param ? path.replace(`/${param}`, "") : path;

  return basePath === "" ? "/" : basePath;
};

const getPathWithParams = (basePath, param) => {
  let path = basePath === "/" ? "" : basePath;

  return `${path}/${param}`;
};

export default function LayoutSwitcher() {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const params = useParams();
  const { visitId } = params;

  const pathname = getPathWithoutParams(history.location.pathname, visitId);

  const routes = [paths.HOME, paths.MAP];

  const [visits, setVisits] = useState([]);
  const [visitCard, setVisitCard] = React.useState({
    isOpen: visitId ? true : false,
    visit: {},
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVisits();
  }, []);

  useEffect(() => {
    if (visitId === "visit-demo") {
      setVisitCard({ ...visitCard, visit: visitDemo });
      return;
    }

    if (
      visitId &&
      visitId !== "visit-demo" &&
      visitCard.isOpen &&
      _.isEmpty(visitCard.visit)
    ) {
      console.log("VisitID", visitId);
      fetchVisit();
    }
  }, [visitId, visitCard.isOpen, visitCard.visit]);

  const fetchVisit = async () => {
    try {
      setLoading(true);
      const id = visitId.replace("visit-", "");
      const result = await visitService.getVisitById(id);
      setVisitCard({ ...visitCard, visit: result.data.visit });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchVisits = async (fields = {}) => {
    try {
      setLoading(true);
      const result = await visitService.getVisits(fields);
      if (result) {
        //console.log(result);
        setVisits(result.data.visits);
      }
      //setVisits(visitService.getFakeVisits(10));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenVisitCard = (visit) => {
    setVisitCard({ isOpen: true, visit });
    history.replace(getPathWithParams(pathname, `visit-${visit.id}`));
  };
  const handleCloseVisitCard = () => {
    setVisitCard({ isOpen: false, visit: {} });
    history.replace(pathname);
  };

  const handleOnSearch = (fields) => {
    fetchVisits(fields);
    console.log(fields);
  };

  return (
    <>
      <ModalVisitCard
        isOpen={visitCard.isOpen}
        handleClose={handleCloseVisitCard}
        visit={visitCard.visit}
        visitId={visitId}
        loading={loading}
      />
      <div className={classes.root}>
        <SearchBox onSearch={handleOnSearch} selectedLayout={pathname} />
        <Box className={classes.layoutContainer}>
          <div className={classes.headContainer}>
            {/* No visit found*/}
            {!loading &&
              (!visits || _.isEmpty(visits) ? (
                <Alert
                  severity="warning"
                  variant="outlined"
                  style={{ alignItems: "center" }}
                >
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {t("content.homePage.noVisits")}
                  </Typography>
                </Alert>
              ) : (
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ fontWeight: 600 }}
                >
                  {t("searchBox.visits.visitWithCount", {
                    count: visits.length,
                  })}
                </Typography>
              ))}
            <Tabs
              value={pathname}
              indicatorColor="primary"
              textColor="primary"
              aria-label="layout-switch"
              //orientation="vertical"
              classes={{
                flexContainer: classes.flexContainer,
                //indicator: classes.indicator,
                root: classes.tabsRoot,
                scroller: classes.scroller,
              }}
              className={classes.tabs}
              onChange={(_, value) => {
                history.replace(value);
              }}
            >
              <Tab icon={<AppsIcon />} value={routes[0]} />
              <Tab icon={<MapOutlinedIcon />} value={routes[1]} />
            </Tabs>
          </div>

          <SwitchItem value={pathname} route={routes[0]}>
            <LayoutVisits
              visits={visits}
              onOpenCard={handleOpenVisitCard}
              loading={loading}
            />
          </SwitchItem>
          <SwitchItem value={pathname} route={routes[1]}>
            <Box height="70vh">
              <Map visits={visits} onOpenCard={handleOpenVisitCard} />
            </Box>
          </SwitchItem>
        </Box>
      </div>
    </>
  );
}
