import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LandingAuth from "./LandingAuth";
import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 900,
  },

  subTitle: {
    fontWeight: 600,
  },

  content: {},
}));

const SideBar = () => {
  return <></>;
};

const LoginLanding = ({ location }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return <LandingAuth form={LoginForm} sidebar={SideBar} location={location} />;
};

export default LoginLanding;
