import React from "react";

const SwitchItem = ({ children, value, route }) => {
  const check = value === route;

  return (
    <div style={{ width: check ? "100%" : null }}>{check && children}</div>
  );
};

export default SwitchItem;
