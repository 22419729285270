import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
//import VisitForm from "../Admin/VisitForm";
//import VisitGeoForm from "../Admin/VisitGeoForm";
//import GeoAutoComplete from "../Admin/GeoAutoComplete";
import XVisitsGeoManager from "../DataGrid/XVisitsGeoManager";
import XVisitsManager from "../DataGrid/XVisitsManager";
import AdminDashboard from "../Dashboard/AdminDashboard";
import { paths } from "./paths";
import XUsersManager from "../DataGrid/XUsersManager";
import LiveLinkGenerator from "../Admin/LiveFeature/LiveLinkGenerator";

export default function AdminMain() {
  const { path, url } = useRouteMatch();
  const { admin } = paths;

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}${admin.VISITS}`}
          component={XVisitsManager}
        />
        <Route
          exact
          path={`${path}${admin.VISITS_GEO}`}
          component={XVisitsGeoManager}
        />
        <Route exact path={`${path}${admin.USERS}`} component={XUsersManager} />
        <Route
          exact
          path={`${path}${admin.DASHBOARD}`}
          component={AdminDashboard}
        />
        <Route
          exact
          path={`${path}${admin.LIVE_LINK_GENERATOR}`}
          component={LiveLinkGenerator}
        />
        {/* 
        <Route exact path={`${path}/form`} component={VisitForm} />
        <Route exact path={`${path}/formGeo`} component={VisitGeoForm} />
        <Route exact path={`${path}/datagrid`} component={GeoAutoComplete} />
        
        */}

        <Redirect to="/404" />
      </Switch>
    </>
  );
}
