import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authService } from "../../services/auth/authService";
import { mailService } from "../../services/mailService";

import { makeStyles } from "@material-ui/core/styles";
import VisibleVisitIcon from "../Home/VisitCard/VisibleVisitIcon";
import { paths } from "../Routes/paths";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },

  form: {
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: {
    marginBottom: theme.spacing(1.5),
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
}));

const initialSchema = {
  mail: "",
  password: "",
};

const validationSchema = (t) =>
  Yup.object().shape({
    mail: Yup.string(t("loginForm.hints.mail"))
      .email(t("loginForm.errors.mailInvalid"))
      .required(t("loginForm.errors.mail")),
    password: Yup.string(t("loginForm.hints.password")).required(
      t("loginForm.errors.password")
    ),
  });

const textFieldConfig = (
  id,
  label,
  values,
  touched,
  errors,
  handleChange,
  handleBlur
) => {
  return {
    id,
    name: id,
    label: label,
    value: values[id],
    fullWidth: true,
    variant: "outlined",
    margin: "dense",
    helperText: touched[id] ? errors[id] : "",
    error: touched[id] && Boolean(errors[id]),
    onChange: handleChange,
    onBlur: handleBlur,
  };
};

const LoginForm = ({ location }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();

  console.log(location);

  const redirect = (to) => {
    const { state } = location;

    window.location = state ? state.from.pathname : to;
  };

  const handleLoginForm = async (values) => {
    try {
      setError(false);
      //setLoading(true);

      await authService.authenticate(values);
      redirect("/");
    } catch (error) {
      // Invalid account
      const err = error?.response?.data?.error;
      if (err && err === "Invalid account") {
        // send email verification and redirect to 2fa
        const { mail, password } = values;
        try {
          await mailService.sendVerification(mail);
        } catch (error) {
          setError(true);
          console.log(error);
          return;
        }
        history.push("/2fa", {
          mail,
          password,
          from: location.state?.from,
        });
        return;
      }

      setError(true);
    } finally {
      //setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (authService.getCurrentUser()) {
    return <Redirect to={paths.HOME} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h4" color="primary" className={classes.title}>
          {t("loginForm.title")}
        </Typography>
        {error && (
          <Alert severity="error" onClick={() => setError(false)}>
            {t("loginForm.authFailed")}
          </Alert>
        )}
        <Formik
          initialValues={initialSchema}
          validationSchema={validationSchema(t)}
          onSubmit={handleLoginForm}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                {...textFieldConfig(
                  "mail",
                  t("loginForm.labels.mail"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                className={classes.input}
              />

              <TextField
                {...textFieldConfig(
                  "password",
                  t("loginForm.labels.password"),
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        <VisibleVisitIcon isVisible={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.input}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.input}
              >
                {loading ? (
                  <CircularProgress color="primary" />
                ) : (
                  t("loginForm.button")
                )}
              </Button>
            </Form>
          )}
        </Formik>
        <Typography>
          {t("loginForm.newMember")}
          <Link to={paths.SIGNUP}>{t("registerForm.button")}</Link>
        </Typography>
      </div>
    </div>
  );
};

export default LoginForm;
