import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

const isValidParticipant = (participant) => {
  const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(participant.email);

  return participant.name && emailValid;
};

const ParticipantRow = ({ id, participant }) => {
  return (
    <TableRow
      style={{
        backgroundColor: isValidParticipant(participant) ? "" : "#F08080",
      }}
    >
      <TableCell>{id}</TableCell>
      <TableCell>{participant.name}</TableCell>
      <TableCell>{participant.email}</TableCell>
      <TableCell>
        {participant.moderator ? <CheckCircleIcon /> : null}
      </TableCell>
    </TableRow>
  );
};

const ParticipantTable = ({ participants }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nom & Prénom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Guide</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {participants.map((participant, id) => (
            <ParticipantRow key={id} id={id} participant={participant} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ParticipantTable;
