import React from "react";
import {
  Box,
  Chip,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Popup } from "react-leaflet";
import LaunchIcon from "@material-ui/icons/Launch";
import { useTranslation } from "react-i18next";
import { getColor } from "../VisitCard/categoryColor";

const useStyles = makeStyles((theme) => ({
  popup: {
    "& .leaflet-popup-content-wrapper": {
      borderRadius: 3,
    },
    "& .leaflet-popup-close-button": {
      color: "red !important",
      top: "5px !important",
      right: "7px !important",
      fontSize: "25px !important",
    },
  },

  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },

  title: {
    fontWeight: 600,
  },
  button: {
    alignSelf: "flex-end",
  },
  category: {
    alignSelf: "flex-start",
    cursor: "pointer",
  },
  cover: {
    marginTop: theme.spacing(1),
    width: 200,
  },
}));

const VisitPopup = ({ visit, onOpenCard }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation();
  return (
    <Popup maxWidth={200} className={classes.popup}>
      <div
        className={classes.root}
        onClick={() => {
          onOpenCard(visit);
        }}
      >
        <Chip
          size="small"
          label={t(`categories.category${visit.categoryId}`)}
          className={classes.category}
          style={{
            backgroundColor:
              theme.palette.categories[getColor(visit.categoryId)],
            color: theme.palette.common.white,
          }}
        />
        <img className={classes.cover} src={visit.img} alt="" />

        <Typography variant="subtitle1" className={classes.title}>
          {visit.title}
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            onOpenCard(visit);
          }}
          className={classes.button}
        >
          <LaunchIcon />
        </IconButton>
      </div>
    </Popup>
  );
};

export default VisitPopup;
