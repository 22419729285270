import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./custom.scss";
import "fontsource-roboto";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./i18next";

// responsive Typography
let theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#5a189a",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#0066ff",
      main: "#656565",
      // dark: will be calculated from palette.secondary.main,
      //contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,

    categories: {
      museum: "#5858BE",
      monument: "#58BE70",
      archaeology: "#E23232",
      street: "#EA7435",
    },
    //type: "dark",
  },
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
