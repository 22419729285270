import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import MainRouter from "./components/Routes/MainRouter";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ToastContainer />
      <MainRouter />
    </div>
  );
}

export default App;
