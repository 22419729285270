import axios from "axios";
import { tokenService } from "./auth/tokenService";
import { toast } from "material-react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const config = {
  baseURL: API_URL,
  headers: { "x-auth-token": tokenService.getToken() },
  timeout: 2000,
};

export default class HttpService {
  constructor() {
    this.instance = axios.create({
      ...config,
    });

    this.handleErrors();
  }

  handleErrors() {
    this.instance.interceptors.response.use(null, (error) => {
      const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

      if (!expectedError) {
        console.log("Unexpected error ", error);
        toast.error("Unexpected error !");
      }

      return Promise.reject(error);
    });
  }
}

export const httpService = new HttpService().instance;
