import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en, fr } from "./locales";

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    //lng: "fr",
    fallbackLng: "fr",
    debug: process.env.NODE_ENV !== "production",
    //ns: ["translations"],
    //defaultNS: "translations",
    //keySeparator: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
