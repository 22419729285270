import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../Routes/paths";

import { authService } from "../../services/auth/authService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: `${theme.palette.primary.main}09`,
    padding: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  main: {
    fontWeight: 900,
    margin: theme.spacing(1),
  },
  content: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
}));

const Landing = () => {
  const classes = useStyles();

  const [t] = useTranslation();

  return (
    <Box className={classes.root}>
      <Typography
        variant="h2"
        component="h1"
        color="primary"
        className={classes.main}
      >
        {t("appName")}
      </Typography>
      <Typography
        variant="subtitle1"
        component="h2"
        color="primary"
        className={classes.content}
      >
        {t("content.homePage.main")}
      </Typography>
      {!authService.getCurrentUser() && (
        <div>
          <Link
            component={Button}
            color="primary"
            variant="contained"
            className={classes.button}
            to={paths.SIGNUP}
          >
            {t("topBar.signup")}
          </Link>
          <Link
            component={Button}
            color="primary"
            variant="outlined"
            className={classes.button}
            to={paths.VISIT_DEMO}
          >
            {t("content.demoVisit.buttonDemo")}
          </Link>
        </div>
      )}
    </Box>
  );
};

export default Landing;
