import React from "react";
import { Tooltip, IconButton, Avatar } from "@material-ui/core";
import { toast } from "material-react-toastify";

import XDataGrid from "./XDataGrid";
import { Create, Delete, Assignment } from "@material-ui/icons";
import ModelUrlIcon from "./ModelUrlIcon";
import VisibleVisitIcon from "../Home/VisitCard/VisibleVisitIcon";
import PrivateVisitIcon from "../Home/VisitCard/PrivateVisitIcon";

const columns = (onUpdate, onDelete, onUpdateVP) => {
  return [
    { field: "id", headerName: "ID", type: "number", flex: 0.3 },
    {
      field: "isPrivate",
      headerName: "Private",
      renderCell: (params) => (
        <IconButton
          aria-label="isPrivate-id"
          onClick={() => {
            const newValue = {
              id: params.row.id,
              isPrivate: !params.value,
            };
            onUpdateVP(newValue);
          }}
        >
          <PrivateVisitIcon isPrivate={params.value} />
        </IconButton>
      ),
      flex: 0.3,
    },
    {
      field: "isVisible",
      headerName: "Visible",
      renderCell: (params) => (
        <IconButton
          aria-label="isVisible-id"
          onClick={() => {
            const newValue = {
              id: params.row.id,
              isVisible: !params.value,
            };
            onUpdateVP(newValue);
          }}
        >
          <VisibleVisitIcon isVisible={params.value} />
        </IconButton>
      ),
      flex: 0.3,
    },
    { field: "title", headerName: "Title", type: "string", flex: 1 },
    { field: "subtitle", headerName: "Subtitle", type: "string", flex: 1 },
    { field: "description", headerName: "Desc", type: "string", flex: 1 },
    { field: "categoryId", headerName: "Category", type: "string", flex: 0.6 },
    {
      field: "img",
      headerName: "Img",
      renderCell: ({ value }) => (
        <Avatar variant="rounded" alt={value} src={value} />
      ),
      flex: 0.3,
      sortable: false,
    },
    {
      field: "placeId",
      headerName: "Place ID",
      type: "string",
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <IconButton
            aria-label="place-id"
            onClick={() => {
              navigator.clipboard.writeText(value);
              toast.dark("Place ID, copied!");
            }}
          >
            <Assignment />
          </IconButton>
        </Tooltip>
      ),
      flex: 0.4,
      sortable: false,
    },
    {
      field: "modelUrl",
      headerName: "Model",
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <IconButton
            aria-label="map-url"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            <ModelUrlIcon modelUrl={value} check={false} />
          </IconButton>
        </Tooltip>
      ),
      flex: 0.3,
      sortable: false,
    },
    {
      field: "update",
      headerName: " ",
      renderCell: (params) => (
        <IconButton onClick={() => onUpdate(visitWrapper(params.row))}>
          <Create color="primary" />
        </IconButton>
      ),
      flex: 0.3,
      sortable: false,
    },
    {
      field: "delete",
      headerName: " ",
      renderCell: (params) => (
        <IconButton onClick={() => onDelete(params.row.id)}>
          <Delete color="error" />
        </IconButton>
      ),
      flex: 0.3,
      sortable: false,
    },
  ];
};

const visitWrapper = ({
  id,
  title,
  subtitle,
  description,
  categoryId,
  isPrivate,
  isVisible,
  modelUrl,
  img,
  placeId,
  createdAt,
  updatedAt,
  VisitGeo,
}) => {
  return {
    id,
    title,
    subtitle,
    description,
    categoryId,
    isPrivate,
    isVisible,
    modelUrl,
    img,
    placeId,
    createdAt,
    updatedAt,
    VisitGeo,
  };
};

const XVisitsTable = (props) => {
  const {
    visits,
    onDelete,
    onUpdate,
    onUpdateVP,
    onAdd,
    onReload,
    loading,
  } = props;

  const rowsV = visits.map((value, index) => ({
    ...value,
    update: "",
    delete: "",
  }));

  return (
    <XDataGrid
      rows={rowsV}
      colums={columns(onUpdate, onDelete, onUpdateVP)}
      onAdd={onAdd}
      onReload={onReload}
      loading={loading}
    />
  );
};

export default XVisitsTable;
