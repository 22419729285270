import { Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import LandingAuth from "./LandingAuth";
import RegisterForm from "./RegisterForm";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 900,
  },

  subTitle: {
    fontWeight: 600,
  },

  content: {},
}));

const SideBar = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        color="primary"
        className={classes.title}
        gutterBottom
      >
        {t("content.signup.signupPage")}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        color="primary"
        className={classes.subTitle}
      >
        {t("content.signup.proTitle")}
      </Typography>
      <Typography
        variant="subtitle1"
        component="h2"
        gutterBottom
        color="primary"
        className={classes.content}
      >
        {t("content.signup.proContent")}
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        color="primary"
        className={classes.subTitle}
      >
        {t("content.signup.guideTitle")}
      </Typography>
      <Typography
        variant="subtitle1"
        component="h2"
        gutterBottom
        color="primary"
        className={classes.content}
      >
        {t("content.signup.guideContent")}
      </Typography>
    </>
  );
};

const RegisterLanding = ({ location }) => {
  return (
    <LandingAuth form={RegisterForm} sidebar={SideBar} location={location} />
  );
};

export default RegisterLanding;
