import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { paths } from "../Routes/paths";

import LiveTvIcon from "@material-ui/icons/LiveTv";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MuseumIcon from "@material-ui/icons/Museum";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PeopleIcon from "@material-ui/icons/People";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  nestedList: {
    boxShadow: "inset 0px 2px 2px #00000050",
  },
  nested: {},
}));

export default function AdminDrawer({ pathname }) {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  console.log(pathname);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };

  const { admin: adminPaths } = paths;
  const menu = [
    {
      text: t("adminDrawer.manageVisits"),
      path: adminPaths.HOME + adminPaths.VISITS,
      icon: <MuseumIcon />,
    },
    {
      text: t("adminDrawer.manageVisitsGeo"),
      path: adminPaths.HOME + adminPaths.VISITS_GEO,
      icon: <LocationCityIcon />,
    },
    {
      text: t("adminDrawer.manageUsers"),
      path: adminPaths.HOME + adminPaths.USERS,
      icon: <PeopleIcon />,
    },
    {
      text: t("adminDrawer.dashboard"),
      path: adminPaths.HOME + adminPaths.DASHBOARD,
      icon: <EqualizerIcon />,
    },
    {
      text: t("adminDrawer.liveLinkGenerator"),
      path: adminPaths.HOME + adminPaths.LIVE_LINK_GENERATOR,
      icon: <LiveTvIcon />,
    },

    //{ text: "Form", path: "/admin/form", icon: <AccountCircleIcon /> },
    //{ text: "FormGeo", path: "/admin/formGeo", icon: <AccountCircleIcon /> },
    //{ text: "DataGrid", path: "/admin/datagrid", icon: <HomeIcon /> },
  ];
  return (
    <>
      <ListItem button key="Admin" onClick={handleClick}>
        <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        <ListItemText primary={t("adminDrawer.main")} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nestedList}>
          {menu.map((item) => (
            <ListItem
              component={Link}
              to={item.path}
              button
              key={item.text}
              className={classes.nested}
              selected={item.path === pathname}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
