import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Hidden,
  Chip,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import SearchIcon from "@material-ui/icons/Search";
import { getColor } from "./VisitCard/categoryColor";
import { paths } from "../Routes/paths";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  sortItem: {
    order: 0,
  },
  categoriesItem: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  filterItem: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  searchBox: {
    order: 3,
    justifyContent: "space-between",
  },
  chip: {
    margin: theme.spacing(1),
  },
  field: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
}));

const SearchField = ({ label, keyV, value, handleTyping, className, rest }) => (
  <TextField
    label={label}
    //value={value}
    type="search"
    variant="outlined"
    size="small"
    className={className}
    onChange={(event) => handleTyping(keyV, event.target.value)}
    {...rest}
  />
);

export default function SearchBox({ onSearch, selectedLayout }) {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation();
  const [searchBoxActive, setSearchBox] = useState(true);
  const [fields, setFields] = useState({
    title: "",
    subtitle: "",
    key: "",
    country: "",
    locality: "",
    categories: [],
    orderBy: "createdAt",
    orderDirection: "",
  });
  const [sort, setSort] = useState(0);

  const categories = [1, 2, 3, 4].map((value) => ({
    name: t(`categories.category${value}`),
    value,
    color: getColor(value),
  }));

  /*useEffect(() => {
    //console.log(fields);
    onSearch(fields);
  }, [onSearch, fields.title]);
*/
  const handleSearchBox = () => {
    setSearchBox(!searchBoxActive);
  };

  const handleChipClick = (newCat) => {
    let categories;
    fields.categories.includes(newCat)
      ? (categories = fields.categories.filter((cat) => cat !== newCat))
      : (categories = fields.categories.concat(newCat));

    handleFieldChange("categories", categories);
  };

  const handleFieldChange = (field, value) => {
    const nFields = fields;
    nFields[field] = value;

    setFields({ ...nFields });

    onSearch(nFields);
  };

  const sortWrapper = (value) => {
    switch (value) {
      case 1:
        return "DESC";
      case 2:
        return "ASC";
      default:
        return "DESC";
    }
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    const sort = sortWrapper(value);
    handleFieldChange("orderDirection", sort);

    setSort(value);
  };

  /*const fetchFilterVisits = async () => {
    const query = {
      ...fields,
    };
    try {
      await visitService.getVisits(query);
    } catch (error) {}
  };*/
  return (
    <div className={classes.root}>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={2} className={classes.sortItem}>
          {selectedLayout !== paths.MAP && (
            <TextField
              id="select-sort"
              variant="outlined"
              size="small"
              select
              value={sort}
              onChange={handleSortChange}
            >
              <MenuItem value={0} disabled>
                <em>{t("searchBox.order.placeholder")}</em>
              </MenuItem>
              <MenuItem value={1}>{t("searchBox.order.newest")}</MenuItem>
              <MenuItem value={2}>{t("searchBox.order.oldest")}</MenuItem>
            </TextField>
          )}
        </Grid>
        <Grid
          container
          item
          md={6}
          xs={12}
          className={classes.categoriesItem}
          justify="center"
        >
          {categories.map((category, index) => {
            const { name, value, color } = category;
            const check = fields.categories.includes(value);
            return (
              <Chip
                key={`cat${index}`}
                label={name}
                className={classes.chip}
                onClick={() => {
                  handleChipClick(value);
                }}
                onDelete={
                  check
                    ? () => {
                        handleChipClick(value);
                      }
                    : null
                }
                style={{
                  backgroundColor: theme.palette.categories[color],
                  color: theme.palette.common.white,
                }}
                //deleteIcon={check && null}
              />
            );
          })}
        </Grid>
        <Grid
          container
          justify="flex-end"
          item
          xs={2}
          className={classes.filterItem}
        >
          <IconButton
            color={searchBoxActive ? "default" : "primary"}
            aria-label="filter button"
            //size="small"
            onClick={handleSearchBox}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
        <Hidden lgDown={searchBoxActive}>
          <Grid item container xs={12} className={classes.searchBox}>
            <SearchField
              label={t("searchBox.fields.title")}
              keyV="title"
              value={fields.title}
              className={classes.field}
              handleTyping={handleFieldChange}
            />

            <SearchField
              label={t("searchBox.fields.keyword")}
              keyV="key"
              value={fields.key}
              className={classes.field}
              handleTyping={handleFieldChange}
            />
            <SearchField
              label={t("searchBox.fields.country")}
              keyV="country"
              value={fields.country}
              className={classes.field}
              handleTyping={handleFieldChange}
            />
            <SearchField
              label={t("searchBox.fields.locality")}
              keyV="locality"
              value={fields.locality}
              className={classes.field}
              handleTyping={handleFieldChange}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

//export interface IVisitQuery {
//  title?: string;
//  subtitle?: string;
//  categories?: Array<number>;
//  key?: string;
//
//  orderBy?: string;
//  orderDirection?: string;
//  limit?: number;
//  offset?: number;
//
//  excludeAttr?: Array<string>;
//}
