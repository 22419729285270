import React from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: 180,
    minHeight: 160,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  value: {
    textAlign: "center",
  },
  source: {
    textAlign: "center",
  },
}));

const DashbordCard = ({ source, value, loading }) => {
  const classes = useStyle();
  return (
    <Grid
      classes={{ root: classes.root }}
      component={Card}
      item
      xs={12}
      sm={6}
      //md={3}
      //lg={2}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <CardContent>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="h1"
            variant="h2"
          >
            {value}
          </Typography>
          <Typography
            className={classes.source}
            color="textSecondary"
            variant="subtitle1"
          >
            {source}
          </Typography>
        </CardContent>
      )}
    </Grid>
  );
};

export default DashbordCard;
