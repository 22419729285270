import React from "react";
import XDataGrid from "./XDataGrid";
import { Create, Delete, Language, Assignment } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
const columns = (onUpdate, onDelete) => {
  return [
    { field: "userId", headerName: "ID", type: "number", flex: 0.3 },
    { field: "mail", headerName: "Mail", type: "string", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      renderCell: (params) => {
        const active = params.value;
        return (
          <IconButton aria-label="isActive-user">
            {active ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
          </IconButton>
        );
      },
      flex: 1,
    },
    { field: "firstName", headerName: "First name", type: "string", flex: 1 },
    { field: "lastName", headerName: "Last name", type: "string", flex: 1 },
  ];
};

const userWrapper = ({ userId, mail, active, firstName, lastName }) => ({
  userId,
  mail,
  active,
  firstName,
  lastName,
});

const XUsersTable = (props) => {
  const { users, onDelete, onUpdate, onAdd, onReload, loading } = props;

  const rowsV = users.map((user, index) => ({
    ...user,
    id: index,
  }));
  return (
    <XDataGrid
      rows={rowsV}
      colums={columns(onUpdate, onDelete)}
      //onAdd={onAdd}
      onReload={onReload}
      loading={loading}
    />
  );
};

export default XUsersTable;
