import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { authService } from "../../services/auth/authService";
import { grantAccess } from "../../roles/accessControl";
import { actions } from "../../roles/constants";
import { paths } from "./paths";

const ProtectedRoute = ({ component: Component, protectedRoute, ...rest }) => {
  const user = authService.getCurrentUser();
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        // route is not protected
        if (!protectedRoute) return <Component {...props} />;

        // no user is logged in
        if (!user)
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        const permission = grantAccess(
          actions.ACCESS,
          protectedRoute,
          user.roleName
        );
        if (permission) return <Component {...props} />;
        else {
          history.push(paths.NOT_FOUND);
          return;
        }
      }}
    />
  );
};

export default ProtectedRoute;
