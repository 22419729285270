const tokenKey = "token";

export default class TokenService {
  getToken() {
    return localStorage.getItem(tokenKey);
  }

  setToken(token) {
    localStorage.setItem(tokenKey, token);
  }

  removeToken() {
    localStorage.removeItem(tokenKey);
  }
}

export const tokenService = new TokenService();
