import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Toolbar,
  IconButton,
  AppBar,
  Slide,
  Box,
  Typography,
  DialogContentText,
  Grid,
  Chip,
  Divider,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import VisitIFrame from "../../IFrame/VisitIFrame";
import VisitGeoCard from "./VisitGeoCard";
import RedirectedIFrame from "../../IFrame/RedirectedIFrame";
import { getColor } from "./categoryColor";

import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "rgb(0,0,0,.6)",
  },
  toolbar: {
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  appBar: {},
  dialogContent: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.background.default,
    height: "100%",
    borderTopLeftRadius: 12,
    overflowX: "hidden",
  },

  mainContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
  },

  secondaryContent: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  title: {
    display: "flex",
    alignItems: "end",
    "& > svg": {
      margin: theme.spacing(1),
    },
  },

  dividers: {
    borderBottom: "none",
  },
  element: {
    margin: theme.spacing(2, 0),
  },

  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: theme.spacing(0, 2),
  },
  avatarDivider: {
    flexGrow: 1,
    alignSelf: "center",
    height: 2,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalVisitCard = ({ isOpen, handleClose, visit, loading }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      classes={{ paper: classes.dialog }}
    >
      <AppBar
        position="sticky"
        classes={classes.appBar}
        color="transparent"
        elevation={0}
      >
        <Toolbar className={classes.toolbar} onClick={handleClose}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon color="error" fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.dialogContent}>
        {loading ? (
          <LinearProgress />
        ) : visit === undefined || _.isEmpty(visit) ? (
          <h1>Not found</h1>
        ) : (
          <Grid container spacing={3}>
            <Grid item sm={1} md />

            <Grid
              item
              sm={12}
              xs={12}
              md={8}
              lg={7}
              xl={6}
              classes={{ root: classes.mainContent }}
            >
              <DialogTitle disableTypography>
                <Typography variant="h4" component="h2">
                  {visit.title}
                </Typography>
                <Box className={classes.title}>
                  <Box style={{ flexGrow: 1 }}>
                    <Chip
                      label={t(`categories.category${visit.categoryId}`)}
                      style={{
                        backgroundColor:
                          theme.palette.categories[getColor(visit.categoryId)],
                        color: theme.palette.common.white,
                      }}
                    />
                  </Box>
                </Box>
              </DialogTitle>
              <DialogContent
                dividers
                classes={{
                  dividers: classes.dividers,
                  root: classes.secondaryContent,
                }}
              >
                {visit.modelUrl ? (
                  <VisitIFrame modelUrl={visit.modelUrl} />
                ) : (
                  <RedirectedIFrame cover={visit.img} />
                )}
                <DialogContentText
                  color="textPrimary"
                  variant="h5"
                  component="h2"
                  className={classes.element}
                >
                  {t("visitCardModal.description")}
                </DialogContentText>
                <DialogContentText variant="body1">
                  {visit.description}
                </DialogContentText>
                <Grid container justify="center">
                  <Divider className={classes.avatarDivider} />

                  <Avatar
                    alt={visit.title}
                    src={visit.img}
                    className={classes.avatar}
                  />
                  <Divider className={classes.avatarDivider} />
                </Grid>
                <VisitGeoCard
                  visitGeo={visit.VisitGeo}
                  className={classes.element}
                />
              </DialogContent>
            </Grid>
            <Grid item sm={1} md />
          </Grid>
        )}
      </Box>
    </Dialog>
  );
};

export default ModalVisitCard;
