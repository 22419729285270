import { Box, Button, makeStyles, Select, TextField } from "@material-ui/core";
import React, { useState } from "react";
import uuid from "uuid-random";
import { mailService } from "../../../services/mailService";
import { generateLink } from "./links_generator";
import ParticipantsBox from "./ParticipantsBox";
import { saveSheet } from "./xlsx_generator";
import { toast } from "material-react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  textfied: {
    margin: theme.spacing(1),
    maxWidth: 400,
  },
  input: {
    margin: theme.spacing(1),
    "& textarea": {
      lineHeight: "1.8rem",
    },
  },
  container: {
    display: "flex",
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  buttons: {
    display: "flex",
  },
  button: {
    flex: 1,
    margin: theme.spacing(1),
  },
}));

const getDate = (date) => {
  return date.toISOString().substr(0, 10);
};

const getTime = (date) => {
  return date.toLocaleTimeString("it-IT", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getLink = (room, token) => {
  return `https://live.explorvisit.com/${room}?jwt=${token}`;
};

const toTimeStamps = (date) => {
  return date.getTime() / 1000;
};

const specialCharsRegex = /[*|":<>[\]{}`\\()';@&$#\s]/;

export const handleParticipantsInput = (input) => {
  const participants = [];
  if (!input) return participants;

  const lines = input.split("\n");
  lines.forEach((line) => {
    const infos = line.split("/");
    // 0 name
    // 1 email , default nom.prenom@explorvisit.com
    // 2 moderator, default false
    const name = infos[0]?.trim() || "";
    const email = infos[1]?.trim();
    const moderator = infos[2]?.trim() === "true" || false;
    participants.push({ name, email, moderator });
  });

  return participants;
};

const LiveLinkGenerator = () => {
  const classes = useStyles();
  const [fields, setFields] = useState({
    room: "",
    fullVisitName: "",
    iatDate: getDate(new Date()),
    nbfTime: getTime(new Date()),
    expTime: getTime(new Date()),
    input: "",
    provider: "matterport",
    modelId: "",
  });

  const handleField = (event) => {
    const { id, value } = event.target;
    setFields({ ...fields, [id]: value });
  };

  const generateData = () => {
    const pInput = handleParticipantsInput(fields.input);
    if (pInput.length === 0) return;

    const {
      iatDate,
      expTime,
      nbfTime,
      room,
      modelId,
      provider,
      fullVisitName,
    } = fields;
    const nbfDate = new Date(iatDate + "T" + nbfTime);
    const expDate = new Date(iatDate + "T" + expTime);

    const participants = [];

    pInput.forEach((participant) => {
      const { name, email, moderator } = participant;
      const token = generateLink(
        { id: uuid(), name, email, avatar: "" },
        {
          room,
          nbf: toTimeStamps(nbfDate),
          exp: toTimeStamps(expDate),
          moderator,
          isGuide: moderator,
          provider,
          modelUrl: modelId,
        }
      );

      const link = getLink(room, token);
      participants.push({ ...participant, link });
    });

    return {
      room,
      fullVisitName,
      iatDate,
      expTime,
      nbfTime,
      modelId,
      participants,
    };
  };

  const generateFile = () => {
    const data = generateData();
    saveSheet(data);
  };

  const sendMails = () => {
    const { fullVisitName, iatDate, expTime, nbfTime, participants } =
      generateData();

    const dateFrom = new Date(iatDate + "T" + nbfTime + "+00:00");
    const dateTo = new Date(iatDate + "T" + expTime + "+00:00");

    const visit = {
      name: fullVisitName,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
    };

    const data = participants.map(({ name, email, moderator, link }) => {
      if (moderator) {
        visit.guideName = name;
      }

      return {
        name,
        email,
        isGuide: moderator,
        link,
      };
    });

    mailService
      .sendTourGuideMails({ visit, participants: data })
      .then(() => {
        toast.success("Liens envoyés !");
        console.log("emails sent succesfully");
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err);
      });
  };

  const canGenerate = () => {
    const { input } = fields;

    return (
      isRoomNameValid() &&
      isModelUrlValid() &&
      isExpTimeValid() &&
      input?.length > 0
    );
  };

  const isRoomNameValid = () => {
    const { room } = fields;
    return room?.length > 0 && !specialCharsRegex.test(room);
  };

  const isModelUrlValid = () => {
    return fields.modelId?.length > 0;
  };

  const isExpTimeValid = () => {
    const { nbfTime, expTime } = fields;
    return expTime && expTime > nbfTime;
  };

  return (
    <Box p={3} className={classes.root}>
      <div className={classes.container}>
        <div className={classes.fieldsContainer}>
          <TextField
            id="room"
            variant="outlined"
            label="Nom abrégé de la visite"
            placeholder="ex. Bayeux"
            value={fields.room}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textfied}
            onChange={handleField}
            error={!isRoomNameValid()}
          >
            {fields.room}
          </TextField>
          <TextField
            id="fullVisitName"
            variant="outlined"
            label="Nom complet de la visite"
            placeholder="ex. Musée d’art et d’histoire Baron Gérard à Bayeux"
            value={fields.fullVisitName}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textfied}
            onChange={handleField}
            error={fields.fullVisitName.length === 0}
          >
            {fields.fullVisitName}
          </TextField>
          <TextField
            id="iatDate"
            label="Date"
            type="date"
            value={fields.iatDate}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textfied}
            onChange={handleField}
          />
          <TextField
            id="nbfTime"
            label="Heure de début"
            type="time"
            value={fields.nbfTime}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textfied}
            onChange={handleField}
          />
          <TextField
            id="expTime"
            label="Heure de fin"
            type="time"
            value={fields.expTime}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textfied}
            onChange={handleField}
            error={!isExpTimeValid()}
          />
          <Select
            labelId="provider"
            id="provider"
            value={fields.provider}
            onChange={handleField}
            variant="outlined"
            native
            className={classes.textfied}
          >
            <option value={"matterport"}>Matterport</option>
            <option value={"kuula"}>Kuula</option>
          </Select>
          <TextField
            id="modelId"
            label="ID du modèle"
            value={fields.modelId}
            variant="outlined"
            placeholder="ex. EZDH52TGmqx"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textfied}
            onChange={handleField}
            error={!isModelUrlValid(fields.modelId)}
          />
        </div>
        {isModelUrlValid() && (
          <div className={classes.fieldsContainer}>
            <iframe
              title="matterport-frame"
              id="frame"
              height="100%"
              width="100%"
              frameBorder="0"
              src={
                fields.provider === "kuula"
                  ? `https://kuula.co/share/${fields.modelId}`
                  : `https://my.matterport.com/show/?m=${fields.modelId}&qs=1`
              }
            ></iframe>
          </div>
        )}
      </div>

      <ParticipantsBox
        onChangeField={handleField}
        participants={fields.input}
      />
      <div className={classes.buttons}>
        <Button
          color="primary"
          variant="contained"
          onClick={generateFile}
          disabled={!canGenerate()}
          className={classes.button}
        >
          Générer un fichier
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={sendMails}
          disabled={!canGenerate()}
          className={classes.button}
        >
          Envoyer des emails
        </Button>
      </div>
    </Box>
  );
};

export default LiveLinkGenerator;
