import React from "react";
import { LinearProgress, Grid, Typography } from "@material-ui/core";
//import InfiniteScroll from "react-infinite-scroller";
import VisitCard from "../VisitCard/VisitCard";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";

const LayoutVisits = ({ visits, onOpenCard, loading }) => {
  const [t] = useTranslation();

  if (loading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <>
      <Grid container spacing={0} alignItems="stretch" justify="center">
        {visits &&
          visits.map((visit, index) => (
            <VisitCard
              key={`card-${index}`}
              visit={visit}
              onOpenCard={onOpenCard}
            />
          ))}
      </Grid>
    </>
  );
};

export default LayoutVisits;
