import XLSX from "xlsx";
import { saveAs } from "file-saver";

export const generateSheet = (data) => {
  // create a book
  const wb = XLSX.utils.book_new();

  wb.Props = {
    Title: "Meeting",
    Author: "Explor Visit",
    CreatedDate: new Date(),
  };

  //const sheetName = "Test sheet";
  //wb.SheetNames.push(sheetName);

  //XLSX.utils.sheet_add_json(wb.Sheets[sheetName], data, {});
  const { iatDate, expTime, nbfTime, room, modelId, participants } = data;

  const sheet = XLSX.utils.json_to_sheet(participants, {
    origin: "A10",
    skipHeader: true,
  });

  XLSX.utils.sheet_add_aoa(sheet, [["Nom", "Email", "Guide", "Lien"]], {
    origin: "A9",
  });

  XLSX.utils.sheet_add_aoa(sheet, [["Nom de la visite", room]], {
    origin: "A1",
  });
  XLSX.utils.sheet_add_aoa(sheet, [["Date de création"]], {
    origin: "A2",
  });
  XLSX.utils.sheet_add_aoa(sheet, [[new Date().toISOString()]], {
    origin: "C2",
  });
  XLSX.utils.sheet_add_aoa(sheet, [["Pour le", iatDate]], {
    origin: "A3",
  });

  XLSX.utils.sheet_add_aoa(sheet, [["Heure de début", nbfTime]], {
    origin: "A4",
  });
  XLSX.utils.sheet_add_aoa(sheet, [["Heure de fin", expTime]], {
    origin: "A5",
  });

  XLSX.utils.sheet_add_aoa(sheet, [["Modèle ID", modelId]], {
    origin: "A6",
  });

  XLSX.utils.sheet_add_aoa(
    sheet,
    [["Guides", participants.filter((p) => p.moderator === true).length]],
    {
      origin: "A7",
    }
  );
  XLSX.utils.sheet_add_aoa(
    sheet,
    [["Invités", participants.filter((p) => p.moderator === false).length]],
    {
      origin: "A8",
    }
  );

  XLSX.utils.book_append_sheet(wb, sheet, "Test sheet");

  return XLSX.write(wb, { type: "binary", bookType: "xlsx" });
};

export function sheet2buffer(bData) {
  const len = bData.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < len; i++) view[i] = bData.charCodeAt(i) & 0xff;

  return buffer;
}

export function saveSheet(data) {
  const sheet = generateSheet(data);
  const buffer = sheet2buffer(sheet);

  saveAs(new Blob([buffer], { type: "application/octet-stream" }), "test.xlsx");
}
