import jsonwebtoken from "jsonwebtoken";

const APP_KEY = process.env.REACT_APP_LIVE_LINK_APP_KEY;
const APP_ID = process.env.REACT_APP_LIVE_LINK_APP_ID;

export const generateLink = (
  { id, name, email, avatar },
  { room, nbf, exp, moderator, isGuide, provider, modelUrl }
) => {
  return jsonwebtoken.sign(
    {
      context: {
        user: {
          id,
          name,
          email,
          avatar,
        },
      },
      guide: {
        isGuide,
        provider,
        modelUrl,
      },
      aud: "explorvisit",
      iss: "explorvisit",
      sub: APP_ID,
      room,
      nbf,
      exp,
      moderator,
    },
    APP_KEY,
    { header: { kid: `${APP_ID}/${room}` } }
  );
};
