import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, IconButton, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import MapPreveiw from "../Map/MapPreview";

import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    alignSelf: "center",
    position: "relative",
  },
  media: {
    minHeight: 200,
    maxHeight: 200,
  },
  address: {
    flex: "0 0 100%",
  },
  itemText: {
    margin: theme.spacing(1),
    "& .MuiListItemText-primary": {
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.text.secondary,
    },

    "& .MuiListItemText-secondary": {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export default function VisitGeoCard({ visitGeo, className }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const noInformation = "N/A";

  if (visitGeo === undefined) return <h1>{t("visitCardModal.empty")}</h1>;

  return (
    <Grid
      container
      spacing={0}
      component={Card}
      className={[classes.root, className]}
    >
      <Grid item xs={12} sm={5} className={classes.media}>
        <MapPreveiw visitGeo={visitGeo} />
      </Grid>

      <Grid item xs={12} sm container>
        <ListItemText
          primary={t("visitGeoForm.labels.formattedAddress")}
          secondary={visitGeo.streetAddress || noInformation}
          className={[classes.itemText, classes.address]}
          classes={{
            primary: classes.itemText.primary,
            secondary: classes.itemText.secondary,
          }}
        />
        <ListItemText
          primary={t("visitGeoForm.labels.postalCode")}
          secondary={visitGeo.postalCode || noInformation}
          className={classes.itemText}
          classes={{
            primary: classes.itemText.primary,
            secondary: classes.itemText.secondary,
          }}
        />
        <ListItemText
          primary={t("visitGeoForm.labels.locality")}
          secondary={visitGeo.locality || noInformation}
          className={classes.itemText}
          classes={{
            primary: classes.itemText.primary,
            secondary: classes.itemText.secondary,
          }}
        />
        <ListItemText
          primary={t("visitGeoForm.labels.country")}
          secondary={visitGeo.country || noInformation}
          className={classes.itemText}
          classes={{
            primary: classes.itemText.primary,
            secondary: classes.itemText.secondary,
          }}
        />

        {/* url */}
      </Grid>
    </Grid>
  );
}
