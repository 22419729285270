import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const VisibleVisitIcon = ({ isVisible, color }) => {
  return (
    <>
      {isVisible ? (
        <VisibilityIcon color={color} />
      ) : (
        <VisibilityOffIcon color={color} />
      )}
    </>
  );
};

export default VisibleVisitIcon;
