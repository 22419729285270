import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ExplorVisitLogo from "../Home/Logo";
import { Link } from "react-router-dom";
import { paths } from "../Routes/paths";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  form: {
    padding: theme.spacing(5),
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: `${theme.palette.primary.main}20`,
    padding: theme.spacing(5),
  },
  logo: {
    height: "fit-content",
    marginBottom: theme.spacing(2),
    filter: "invert(100%)",
  },
}));

const LandingAuth = ({ form: FormComponent, sidebar: SideBar, location }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.sidebar} xs={12} md={6}>
          <Link to={paths.HOME} className={classes.logo}>
            <ExplorVisitLogo />
          </Link>
          <SideBar />
        </Grid>
        <Grid item className={classes.form} xs={12} md={6}>
          <FormComponent location={location} />
        </Grid>
      </Grid>
    </>
  );
};

export default LandingAuth;
