const roles = {
  GUEST: "GUEST",
  VISITOR: "VISITOR",
  VISITOR_PR: "VISITOR_PR",
  GUIDE_FREE: "GUIDE_FREE",
  GUIDE_ATT: "GUIDE_ATT",
  PRO: "PRO",
  ADMIN: "ADMIN",
};

const routes = {
  OWN_PROFILE: "ME",
  USER_PROFILE: "ADMIN",
  ADMIN: "ADMIN",
};

const components = {
  PROFILE_AVATAR: "PROFILE_AVATAR",
  PROFILE_DRAWER: "PROFILE_DRAWER",
  ADMIN_DRAWER: "AMDMIN_DRAWER",
};

const actions = {
  ACCESS: "ACCESS",
};

export { roles, routes, components, actions };
