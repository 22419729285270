import React from "react";
import { useTranslation } from "react-i18next";
import { userService } from "../../services/userService";

import DashboardDeck from "./DashboardDeck";

const UsersDeck = () => {
  const [t] = useTranslation();

  const fetchData = async () => {
    const result = await userService.getUsersCount();
    return result.data.map((row) => ({
      source: row.roleName,
      value: row.total,
    }));
  };

  const rowWrapper = (roleName, value) => {
    //const role = roleName === "all" ? roleName : `${roleName}`;
    return {
      source: t(`roles.${roleName?.toLowerCase()}`),
      value,
    };
  };

  const defaultData = [
    "guest",
    "visitor",
    "visitor_pr",
    "guide_free",
    "guide_att",
    "pro",
    "admin",
  ];

  return (
    <DashboardDeck
      title={t("adminDashboard.users")}
      fetchData={fetchData}
      rowWrapper={rowWrapper}
      defaultData={defaultData}
    />
  );
};

export default UsersDeck;
