import Language from "@material-ui/icons/Language";
import { visitService } from "../../services/Visits/visitService";

const ModelUrlIcon = ({ modelUrl, check }) => {
  const isValid = async () => {
    try {
      await visitService.isValidModel(modelUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  return <Language color={check && !isValid() ? "error" : "action"} />;
};

export default ModelUrlIcon;
