import { AccessControl } from "role-acl";
import { roles, routes, components, actions } from "./constants";

export const acl = new AccessControl();

acl
  // GUEST
  .grant(roles.GUEST)

  // VISITOR
  .grant(roles.VISITOR)
  //.extend(roles.GUEST)
  .execute(actions.ACCESS)
  .on([
    routes.OWN_PROFILE,
    components.PROFILE_AVATAR,
    components.PROFILE_DRAWER,
  ])

  // VISITOR _ PREMIUM
  .grant(roles.VISITOR_PR)
  .extend(roles.VISITOR)

  // GUIDE _ ATTACHED
  .grant(roles.GUIDE_ATT)
  .extend(roles.VISITOR)

  // GUIDE _ FREELANCE
  .grant(roles.GUIDE_FREE)
  .extend(roles.GUIDE_ATT)

  // PRO
  .grant(roles.PRO)
  .extend(roles.VISITOR)

  // ADMIN
  .grant({
    role: roles.ADMIN,
    action: "*",
    resource: "*",
    attributes: "[*]",
  });

export function grantAccess(action, ressource, roleName) {
  try {
    const permission = acl.can(roleName).execute(action).sync().on(ressource);

    return permission.granted;
  } catch (error) {
    return false;
  }
}
