export function getColor(category) {
  switch (category) {
    case 1:
      return "museum";
    case 2:
      return "monument";
    case 3:
      return "archaeology";
    case 4:
      return "street";
    default:
      return "museum";
  }
}
