// Imports
import React from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Script from "react-load-script";
import "./GeoAutoComplete.styles.css";

const GeoAutoComplete = ({ onPlaceSelect }) => {
  const [t] = useTranslation();
  const autocomplete = { current: null };

  const handleScriptLoad = () => {
    if (autocomplete.current) {
      return;
    }
    // Declare Options For Autocomplete
    const options = {
      fields: [
        "adr_address",
        "formatted_address",
        "geometry",
        "name",
        "place_id",
        "url",
      ],
    };

    const google = window.google;
    autocomplete.current = new google.maps.places.Autocomplete(
      document.getElementById("pac-input"),
      options
    );

    autocomplete.current.addListener("place_changed", () =>
      handlePlaceSelect(autocomplete)
    );
  };

  const handlePlaceSelect = (autocomplete) => {
    const result = autocomplete.current.getPlace();

    let visitGeo;
    try {
      visitGeo = resultWrapper(result);
    } catch (error) {
      visitGeo = undefined;
    }

    onPlaceSelect(visitGeo);
    //setValues({ ...visitGeo });
  };

  const resultWrapper = (result) => {
    const address = new DOMParser().parseFromString(
      result.adr_address,
      "text/html"
    );
    const streetAddress = address.querySelector(".street-address");
    const postalCode = address.querySelector(".postal-code");
    const locality = address.querySelector(".locality");
    const country = address.querySelector(".country-name");

    const visitGeo = {
      placeId: result.place_id,
      name: result.name,
      formattedAddress: result.formatted_address,
      streetAddress: streetAddress ? streetAddress.textContent : "n/a",
      postalCode: postalCode ? postalCode.textContent : "n/a",
      locality: locality ? locality.textContent : "n/a",
      country: country ? country.textContent : "n/a",
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
      url: result.url,
    };

    return visitGeo;
  };

  React.useEffect(() => {
    if (window.google) handleScriptLoad();
  }, []);

  return (
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <TextField
        id="pac-input"
        type="text"
        placeholder={t("visitGeoForm.hints.location")}
        variant="outlined"
        fullWidth
        margin="dense"
        //InputProps={{ className: classes.input }}
      />
    </div>
  );
};

export default GeoAutoComplete;
