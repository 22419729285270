import { httpService } from "../httpService";
import visits from "./fakeDatabase/visits.json";

const visitsGeo = visits.map(({ VisitGeo }) => VisitGeo);

export default class VisitGeoService {
  visitsGeoUrl(placeId) {
    return `visitsGeo/${placeId || ""}`;
  }

  getVisitsGeo = async () => {
    return await httpService.get(this.visitsGeoUrl());
  };

  getVisitGeoById = async (placeId) => {
    return await httpService.get(this.visitsGeoUrl(placeId));
  };
  createVisitGeo = async (visitGeo) => {
    return await httpService.post(this.visitsGeoUrl(), visitGeo);
  };

  updateVisitGeo = async (placeId, visitGeo) => {
    return await httpService.put(this.visitsGeoUrl(placeId), visitGeo);
  };

  deleteVisitGeo = async (placeId) => {
    return await httpService.delete(this.visitsGeoUrl(placeId));
  };

  getMock() {
    return visitsGeo;
  }
}

export const visitGeoService = new VisitGeoService();
