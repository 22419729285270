import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  select: {
    color: "inherit",
    "&:before": {
      content: "''",
      border: "none",
    },
    "&:after": {
      content: "''",
      border: "none",
    },
  },
  icon: {
    color: "inherit",
  },
}));

const LanguageSwitcher = () => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const [currentLanguage, setCurrentLanguage] = React.useState(
    i18n.languages[0]
  );

  const handleChange = (event) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng, () => {
      setCurrentLanguage(lng);
    });
  };
  return (
    <>
      <Select
        labelId="language-switcher"
        id="language-switcher"
        value={currentLanguage}
        onChange={handleChange}
        //native
        //variant="outlined"
        className={classes.select}
        classes={{ icon: classes.icon }}
      >
        <MenuItem value={"en"}>EN</MenuItem>
        <MenuItem value={"fr"}>FR</MenuItem>
      </Select>
    </>
  );
};

export default LanguageSwitcher;
