import React from "react";
import { Link, withRouter } from "react-router-dom";

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
} from "@material-ui/core";
import AdminDrawer from "../Admin/AdminDrawer";
import clsx from "clsx";
import { useStyles } from "./style/MainDrawerStyle";
import { useTranslation } from "react-i18next";
import { paths } from "../Routes/paths";
/**
 * Icons
 */
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HomeIcon from "@material-ui/icons/Home";
import ProtectedComponent from "../Auth/ProtectedComponent";
import { components } from "../../roles/constants";
import ProfileDrawer from "../Profile/ProfileDrawer";
import AuthDrawer from "../Auth/AuthDrawer";

const MainDrawer = withRouter(({ location, ...props }) => {
  const { open, handleDrawer } = props;
  const { pathname } = location;
  const { t } = useTranslation();
  const menu = [
    { text: t("mainDrawer.home"), path: paths.HOME, icon: <HomeIcon /> },
  ];
  const classes = useStyles();
  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.map((item) => (
            <ListItem
              component={Link}
              to={item.path}
              button
              key={item.text}
              selected={pathname === item.path}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          <ProtectedComponent
            component={ProfileDrawer}
            pathname={pathname}
            protectedComponent={components.PROFILE_DRAWER}
          />
          <AuthDrawer />
          <ProtectedComponent
            component={AdminDrawer}
            pathname={pathname}
            protectedComponent={components.ADMIN_DRAWER}
          />
        </List>
        <Divider />
      </Drawer>
    </>
  );
});

export default MainDrawer;
