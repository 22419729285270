import monument from "./monument.png";
import museum from "./museum.png";
import archaeology from "./archaeology.png";
import street from "./street.png";
import L from "leaflet";

export const getMarkerIcon = (category) => {
  let icon;

  switch (category) {
    case 1:
      icon = museum;
      break;
    case 2:
      icon = monument;
      break;
    case 3:
      icon = archaeology;
      break;
    case 4:
      icon = street;
      break;
    default:
      icon = museum;
  }

  const marker = L.icon({
    iconUrl: icon,
    iconSize: [30, 30],
  });
  return marker;
};
