import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { AppBar, Toolbar, IconButton, Button, Box } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

import { authService } from "../../services/auth/authService";
import MainDrawer from "./MainDrawer";
import LanguageSwitcher from "../Home/LanguageSwitcher";

import { useStyles } from "./style/TopBarStyle";
import MenuIcon from "@material-ui/icons/Menu";
import { AccountCircle } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { paths } from "../Routes/paths";
import ExplorVisitLogo from "../Home/Logo";

export default function TopBar({ user }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleRedirect = (path) => {
    console.log(path);
    history.push(path);
  };

  const handleLogoutButton = () => {
    authService.logout();
    window.location = "/";
  };

  return (
    <>
      <AppBar
        title="Explor visit"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.divLogo}>
            <Link to={paths.HOME} className={classes.logo}>
              <ExplorVisitLogo />
            </Link>
          </div>
          {!user ? (
            /* User is not logged */
            <Box display={{ xs: "none", sm: "block" }}>
              <Button component={Link} to={paths.LOGIN} color="inherit">
                {t("topBar.login")}
              </Button>
              <Button component={Link} to={paths.SIGNUP} color="inherit">
                {t("topBar.signup")}
              </Button>
            </Box>
          ) : (
            /* User is logged in*/
            <>
              <IconButton
                aria-label="account of current user"
                //aria-controls="menu-appbar"
                //aria-haspopup="true"
                component={Link}
                to={paths.ME}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Box display={{ xs: "none", sm: "block" }}>
                <Button onClick={handleLogoutButton} color="inherit">
                  {t("topBar.logout")}
                </Button>
              </Box>
            </>
          )}

          <LanguageSwitcher />
        </Toolbar>
      </AppBar>
      <MainDrawer open={open} handleDrawer={handleDrawer} />
    </>
  );
}
