import { httpService } from "../httpService";
import queryString from "query-string";
import visits from "./fakeDatabase/visits.json";

export default class VisitService {
  visitsUrl(visitId) {
    return `visits/${visitId || ""}`;
  }

  getVisits = async (query = {}) => {
    // params for query
    const q = queryString.stringify(query, { skipEmptyString: true });
    const params = queryString.parse(q);

    try {
      const result = await httpService.get(this.visitsUrl(), {
        params,
      });

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  getVisitById = async (visitId) => {
    return await httpService.get(this.visitsUrl(visitId));
  };

  createVisit = async (visit) => {
    return await httpService.post(this.visitsUrl(), visit);
  };

  updateVisit = async (visitId, visit) => {
    return await httpService.put(this.visitsUrl(visitId), visit);
  };

  deleteVisit = async (visitId) => {
    return await httpService.delete(this.visitsUrl(visitId));
  };
  getFakeVisits = (limit) => {
    return visits.splice(0, limit);
  };

  isValidModel = async (modelUrl) => {
    return await httpService.get(modelUrl, { timeout: 2000 });
  };

  getVisitsCount = async () => {
    return await httpService.get(this.visitsUrl("data/count"));
  };
}

export const visitService = new VisitService();
