import React from "react";
import L from "leaflet";

import { MapContainer, TileLayer, Marker, MapConsumer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import mapConfig from "./mapConfig.json";
import { getMarkerIcon } from "./markersLogo/marker";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./Map.css";
import VisitPopup from "./VisitPopup";
import PositionController from "./PositionController";

const createClusterIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "marker-cluster-custom",
    iconSize: L.point(40, 40, true),
  });
};

export default function Map({ visits, onOpenCard }) {
  //const map = useMap();

  return (
    <MapContainer
      center={[mapConfig.centerPosition.lat, mapConfig.centerPosition.lng]}
      zoom={mapConfig.defaultZoom}
      scrollWheelZoom={true}
      //attributionControl={false}
    >
      <TileLayer
        attribution={mapConfig.attribution}
        url={mapConfig.url}
        maxZoom={mapConfig.maxZoom}
        minNativeZoom={mapConfig.minZoom}
        minZoom={mapConfig.minZoom}
        opacity={mapConfig.opacity}
      />
      {visits && (
        <MarkerClusterGroup
          iconCreateFunction={createClusterIcon}
          showCoverageOnHover={false}
        >
          {visits.map((visit, index) => (
            <Marker
              key={index}
              position={[visit.VisitGeo.lat, visit.VisitGeo.lng]}
              icon={getMarkerIcon(visit.categoryId)}
            >
              <VisitPopup visit={visit} onOpenCard={onOpenCard} />
            </Marker>
          ))}
        </MarkerClusterGroup>
      )}
      <PositionController />
    </MapContainer>
  );
}
