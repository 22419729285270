import React, { useEffect, useMemo, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

import MyLocationIcon from "@material-ui/icons/MyLocation";
import { IconButton } from "@material-ui/core";

import "./Map.css";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";

const getPosition = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser"));
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords);
      },
      (err) => {
        reject(err);
      },
      {
        //enableHighAccuracy : true,
        //timeout :
      }
    );
  });
};

const PositionController = () => {
  const map = useMap();
  const [t] = useTranslation();

  useEffect(() => {
    drawPosition({ draw: true });
  }, []);

  const drawPosition = async ({ draw, fly } = {}) => {
    try {
      const position = await getPosition();
      const { latitude: lat, longitude: lng } = position;

      draw && addCircle({ lat, lng });

      fly && map.flyTo([lat, lng], 14, { duration: 1, easeLinearity: 0.1 });
    } catch (error) {
      // geolocalisatino denieed
      error?.code === 1 && toast.warning(t("map.geoPermission"));

      console.log(error);
    }
  };

  const handleFlyPosition = () => {
    drawPosition({ draw: true, fly: true });
  };

  const addCircle = ({ lat, lng }) => {
    layerGroup.clearLayers();

    const icon = L.divIcon({
      className: "marker-position",
      iconSize: L.point(15, 15, true),
    });

    L.marker([lat, lng], { icon }).addTo(layerGroup);
  };

  const layerGroup = useMemo(() => {
    map.createPane("positionPane").style.zIndex = 650;
    return L.layerGroup().addTo(map);
  }, []);

  const buttonControl = useMemo(
    () => (
      <IconButton className="button-control" onClick={handleFlyPosition}>
        <MyLocationIcon />
      </IconButton>
    ),
    []
  );
  return (
    <div className="leaflet-bottom leflet-right buttons-control">
      <div className="leaflet-control leaflet-bar ">{buttonControl}</div>
    </div>
  );
};

export default PositionController;
