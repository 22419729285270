import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { paths } from "../Routes/paths";
import { authService } from "../../services/auth/authService";
import CreateIcon from "@material-ui/icons/Create";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpIcon from "@material-ui/icons/Help";
const AuthDrawer = () => {
  const [t] = useTranslation();

  if (authService.getCurrentUser()) {
    return null;
  }
  const menu = [
    {
      text: t("topBar.login"),
      path: paths.LOGIN,
      icon: <AccountCircleIcon />,
    },
    {
      text: t("topBar.signup"),
      path: paths.SIGNUP,
      icon: <CreateIcon />,
    },
  ];
  return (
    <>
      {menu.map((item) => (
        <ListItem component={Link} to={item.path} button key={item.text}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
      <ListItem
        component={Link}
        to={{ pathname: "https://explorvisit.com/" }}
        button
        key={"aboutUs"}
        target="_blank"
      >
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary={t("topBar.aboutUs")} />
      </ListItem>
    </>
  );
};

export default AuthDrawer;
