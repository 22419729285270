import { httpService } from "./httpService";

export default class MailService {
  sendVerification = async (mail) => {
    return await httpService.post("mail/auth", { to: mail });
  };

  verifyCode = async ({ to, code }) => {
    return await httpService.post("mail/auth/verify", { to, code });
  };

  sendTourGuideMails = async(data) => {
    return await httpService.post("mail/livetour/link", data);
  }
}

export const mailService = new MailService();
