import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";

const PrivateVisitIcon = ({ isPrivate, color }) => {
  return (
    <>
      {isPrivate ? <LockIcon color={color} /> : <LockOpenIcon color={color} />}
    </>
  );
};

export default PrivateVisitIcon;
