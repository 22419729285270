import React from "react";

const ExplorVisitLogo = ({ maxWidth }) => {
  return (
    <img
      src="https://explorvisit.com/wp-content/uploads/2021/02/Logo_02_white.png"
      alt="logo ExplorVisit"
      style={{ maxWidth: maxWidth || 100 }}
    />
  );
};

export default ExplorVisitLogo;
