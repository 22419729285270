import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { Home, Profile, TopBar, NotFound, Unauthorized } from "../index";
import AdminRouter from "./AdminRouter";
import RegisterLanding from "../Auth/RegisterLanding";
import LoginLanding from "../Auth/LoginLanding";
import Auth2FaForm from "../Auth/Auth2FaForm";
import ProtectedRoute from "./ProtectedRoute";
import { routes } from "../../roles/constants";
import { paths } from "./paths";
import { authService } from "../../services/auth/authService";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },

  content: {
    flexGrow: 1,
    //padding: theme.spacing(2),
  },

  toolbar: {
    ...theme.mixins.toolbar,
  },
}));

export default function MainRouter() {
  const classes = useStyles();
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(authService.getCurrentUser());
  }, []);

  return (
    <>
      <Router>
        <div className={classes.root}>
          <Switch>
            <Route exact path={paths.LOGIN} component={LoginLanding} />
            <Route exact path={paths.SIGNUP} component={RegisterLanding} />
            <Route exact path={paths.TWO_FACTOR} component={Auth2FaForm} />
            <Route exact path={paths.UNAUTHORIZED} component={Unauthorized} />
            <Route exact path={paths.NOT_FOUND} component={NotFound} />

            <Route
              path={paths.HOME}
              render={() => (
                <>
                  <TopBar user={user} />
                  <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Switch>
                      <Route
                        exact
                        path={[
                          paths.MAP_VISITS + paths.VISIT_REGEX,
                          paths.HOME_VISITS + paths.VISIT_REGEX,
                        ]}
                        component={Home}
                      />

                      <ProtectedRoute
                        exact
                        path={paths.USER}
                        component={Profile}
                        protectedRoute={routes.USER_PROFILE}
                      />
                      <ProtectedRoute
                        exact
                        path={paths.ME}
                        component={Profile}
                        protectedRoute={routes.OWN_PROFILE}
                      />
                      <ProtectedRoute
                        path={paths.admin.HOME}
                        component={AdminRouter}
                        protectedRoute={routes.ADMIN}
                      />
                      <Redirect to={paths.NOT_FOUND} />
                    </Switch>
                  </main>
                </>
              )}
            />

            <Redirect to={paths.NOT_FOUND} />
          </Switch>
        </div>
      </Router>
    </>
  );
}
