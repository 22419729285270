import React from "react";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import PropTypes from "prop-types";

const ModalForm = ({
  isOpen,
  handleClose,
  handleAction,
  title,
  subtitle,
  children,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-visits"
    >
      <DialogTitle id="form-dialog-visits">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

ModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default ModalForm;
