import React from "react";
import LayoutSwitcher from "./LayoutVisits/LayoutSwitcher";
import Landing from "./Landing";

const Home = () => {
  return (
    <>
      <Landing />
      <LayoutSwitcher />
    </>
  );
};

export default Home;
