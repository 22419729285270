import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  MapConsumer,
} from "react-leaflet";
import L from "leaflet";
import logo from "./markersLogo/marker-icon.png";
import "leaflet/dist/leaflet.css";
import "./Map.css";

const markerIcon = L.icon({
  iconUrl: logo,
  iconSize: [25, 30],
});

export default function MapPreview({ visitGeo }) {
  const { lat, lng } = visitGeo;

  return (
    <MapContainer
      center={[lat, lng]}
      zoom={13}
      scrollWheelZoom={true}
      touchZoom={false}
    >
      <MapConsumer>
        {(map) => {
          map.zoomControl.remove();
          return null;
        }}
      </MapConsumer>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
      />
      <Marker position={[lat, lng]} icon={markerIcon}>
        <Popup>{visitGeo.name}</Popup>
      </Marker>
    </MapContainer>
  );
}
