import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  CardActions,
  Button,
  Typography,
  Grid,
  Chip,
  Box,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { getColor } from "./categoryColor";
// icons
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 320,
    minWidth: 270,
    margin: 8,
    "&:hover": {
      transform: "translateY(-1px)",
      transition: "all 0.1s ease-out",
      boxShadow: theme.shadows[3],
    },
  },

  media: {
    position: "relative",
    minHeight: 250,

    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      bottom: 0,
      zIndex: 1,
      background:
        "linear-gradient(to bottom, transparent,#00000030, #000000CF)",
    },
  },
  content: {
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    width: "100%",
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 800,
    color: theme.palette.common.white,
    textShadow: "2px 2px #00000085",
  },
  locality: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.common.white,
    fontWeight: 300,
    textShadow: "2px 2px #00000085",
  },
  localityIcon: {
    color: theme.palette.common.white,
  },
  category: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    margin: theme.spacing(1),
  },
}));

const VisitCard = ({ visit, onOpenCard }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <Grid
      item
      container
      direction="column"
      justify="space-between"
      component={Card}
      elevation={1}
      className={classes.card}
      xs={12}
      sm={3}
      md={2}
      lg={2}
    >
      <CardActionArea
        className={classes.media}
        onClick={() => {
          onOpenCard(visit);
        }}
      >
        <CardMedia
          component="img"
          alt={visit.title}
          height="170"
          image={visit.img}
          title={visit.title}
          className={classes.media}
        />
        <Chip
          size="small"
          label={t(`categories.category${visit.categoryId}`)}
          className={classes.category}
          style={{
            backgroundColor:
              theme.palette.categories[getColor(visit.categoryId)],
            color: theme.palette.common.white,
          }}
        />
        <Box py={3} px={2} className={classes.content}>
          <Grid container alignItems="center" style={{ marginLeft: -4 }}>
            <Grid item>
              <LocationOnIcon
                color="action"
                fontSize="small"
                className={classes.localityIcon}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" className={classes.locality}>
                {`${visit.VisitGeo.locality}, ${visit.VisitGeo.country}`}
              </Typography>
            </Grid>
          </Grid>

          <Typography gutterBottom variant="h5" className={classes.title}>
            {visit.title}
          </Typography>
        </Box>
      </CardActionArea>
      <CardActions>
        <IconButton
          size="small"
          onClick={() => {
            onOpenCard(visit);
          }}
          style={{ marginLeft: "auto" }}
        >
          <LaunchIcon />
        </IconButton>
      </CardActions>
    </Grid>
  );
};

export default VisitCard;
