import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DashbordCard from "./DashboardCard";
import SyncIcon from "@material-ui/icons/Sync";

const useStyle = makeStyles((theme) => ({
  root: {},
  container: {},
  titleContent: {
    display: "flex",
    alignItems: "center",
  },
}));

const DashboardDeck = ({ title, fetchData, rowWrapper, defaultData }) => {
  const classes = useStyle();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    internalFetch();
  }, []);

  const internalFetch = async () => {
    try {
      setLoading(true);
      const result = await fetchData();
      setData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const wrappedData =
    data.length === 0
      ? defaultData.map((v, i) => rowWrapper(v, "N/A"))
      : data.map((row) => rowWrapper(row.source, row.value));

  // sum of
  const total = data.reduce((prev, curr) => {
    //console.log(prev, curr?.total);
    return prev + curr?.value;
  }, 0);

  const cards = [rowWrapper("all", total), ...wrappedData];

  return (
    <div className={classes.root}>
      <div className={classes.titleContent}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
        <IconButton onClick={internalFetch}>
          <SyncIcon />
        </IconButton>
      </div>
      <Grid className={classes.container} container spacing={2}>
        {cards &&
          cards.map((card, index) => (
            <DashbordCard
              key={index}
              source={card?.source}
              value={card?.value}
              loading={loading}
            />
          ))}
      </Grid>
    </div>
  );
};

export default DashboardDeck;
