import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UsersDeck from "./UsersDeck";
import VisitsDeck from "./VisitsDeck";

const AdminDashboard = () => {
  const [t] = useTranslation();
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {t("adminDashboard.title")}
      </Typography>
      <UsersDeck title={t("adminDashboard.users")} />
      <VisitsDeck title={t("adminDashboard.visits")} />
    </Box>
  );
};

export default AdminDashboard;
