import {
  DataGrid,
  GridOverlay,
  GridToolbarContainer,
  GridFilterToolbarButton,
} from "@material-ui/data-grid";
import { Typography, LinearProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { ErrorOutline, Add, Refresh } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: theme.palette.divider,
    },
  },
  noRowsOverlay: {
    display: "flex",
    flexDirection: "column",
  },
}));

function NoRowsOverlay({ t }) {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.noRowsOverlay}>
      <ErrorOutline color="error" fontSize="large" />
      <Typography
        color="error"
        variant="h5"
        className={classes.noRowsOverlayLabel}
      >
        {t("table.noRows")}
      </Typography>
    </GridOverlay>
  );
}

function LoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function Toolbar({ onAdd, onReload, t }) {
  return (
    <GridToolbarContainer>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Add />}
        onClick={() => onAdd()}
      >
        {t("table.addButton")}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Refresh />}
        onClick={() => onReload()}
      >
        {t("table.reloadButton")}
      </Button>
      <GridFilterToolbarButton />
    </GridToolbarContainer>
  );
}

const XDataGrid = (props) => {
  const { rows, colums, onAdd, onReload, loading } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div style={{ height: 800, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={colums}
        disableColumnMenu
        className={classes.dataGrid}
        hideFooterSelectedRowCount
        autoPageSize
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => NoRowsOverlay({ t }),
          LoadingOverlay: LoadingOverlay,
          Toolbar: () => Toolbar({ onAdd, onReload, t }),
        }}
        loading={loading || false}
      />
    </div>
  );
};

export default XDataGrid;

// Rows
// Colums
// OnAdd
// OnDelete
// OnUpdate
