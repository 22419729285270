import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: 0,
    overflow: "hidden",
    maxWidth: "100%",
    paddingBottom: "56.25%",
  },

  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

const VisitIFrame = ({ modelUrl }) => {
  const classes = useStyles();
  const modelNotFound = "https://my.matterport.com/show/?m=0";
  return (
    <div className={classes.root}>
      <iframe
        frameborder={0}
        title="model"
        src={modelUrl ? modelUrl : modelNotFound}
        allowFullScreen
        //sandbox="allow-same-origin	allow-scripts"
        className={classes.iframe}
      ></iframe>
    </div>
  );
};

export default VisitIFrame;
