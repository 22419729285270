import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { toast } from "material-react-toastify";

import XVisitsTable from "./XVisitsTable";
import VisitForm from "../Admin/VisitForm";
import ModalForm from "../Admin/ModalForm";

import { visitService } from "../../services/Visits/visitService";
import { visitGeoService } from "../../services/Visits/visitGeoService";

export default function XVisitsManager() {
  const [visits, setVisits] = useState([]);
  const [modalFormState, setModalFormState] = useState(false);
  const [currentVisit, setCurrentVisit] = useState(undefined);
  const [currentFormAction, setCurrentFormAction] = useState("");
  const [loading, setLoading] = useState(false);
  //const [erronedModels, setErronedModel] = useState(0);
  const [t] = useTranslation();

  useEffect(() => {
    !modalFormState && loadVisits();
  }, []);

  const loadVisits = async () => {
    try {
      setLoading(true);

      const result = await visitService.getVisits();
      setVisits(result.data.visits);
    } catch (err) {
      console.log(err);
      //toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddVisit = () => {
    setCurrentVisit(undefined);
    setCurrentFormAction("add");
    handleOpenModalForm();
  };

  const handleUpdateVisit = (visit) => {
    setCurrentVisit(visit);
    setCurrentFormAction("update");
    handleOpenModalForm();
  };

  const handleUpdateVP = async (value) => {
    setLoading(true);

    const newVisits = [...visits];
    const oldVisits = visits.splice();
    const index = newVisits.findIndex((visit) => visit.id === value.id);

    const visit = newVisits[index];
    Object.assign(visit, value);

    setVisits(newVisits);
    try {
      await fetchUpdateVisit(visit.id, value);
    } catch (error) {
      console.log(error);
      setVisits(oldVisits);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmitForm = async (visitId, visit, placeId, visitGeo) => {
    try {
      if (currentFormAction === "add") {
        await fetchAddVisitGeo(visitGeo);
        await fetchAddVisit(visit);
      } else if (currentFormAction === "update") {
        // if placeId exists ,update else add new
        console.log("Check", placeId === visitGeo.placeId);
        if (placeId === visitGeo.placeId)
          await fetchUpdateVisitGeo(placeId, visitGeo);
        else {
          await fetchAddVisitGeo(visitGeo);
        }

        await fetchUpdateVisit(visitId, visit);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const fetchAddVisit = async (visit) => {
    try {
      await visitService.createVisit(visit);
      toast.success(t("toasts.addOk"));
      handleCloseModalForm();
    } catch (err) {
      throw err;
    }
  };

  const fetchUpdateVisit = async (visitId, visit) => {
    try {
      await visitService.updateVisit(visitId, visit);
      toast.success(t("toasts.updateOk"));
      handleCloseModalForm();
    } catch (err) {
      throw err;
    }
  };

  const fetchDeleteVisit = async (visitId) => {
    const oldVisits = visits;
    const newVisits = oldVisits.filter((visit) => visit.id !== visitId);
    setVisits(newVisits);

    try {
      await visitService.deleteVisit(visitId);
      toast.success(t("toasts.deleteOk"));
    } catch (err) {
      err.message && toast.error(err.message);
      setVisits(oldVisits);
    }
  };

  const fetchAddVisitGeo = async (visitGeo) => {
    try {
      await visitGeoService.createVisitGeo(visitGeo);
    } catch (err) {
      if (!visitGeoExists(err)) throw err;
    }
  };

  const fetchUpdateVisitGeo = async (placeId, visitGeo) => {
    try {
      await visitGeoService.updateVisitGeo(placeId, visitGeo);
    } catch (err) {
      throw err;
    }
  };

  const visitGeoExists = (error) => {
    const primaryKeyError = error?.response?.data?.fields["visits-geo.PRIMARY"];

    return Boolean(primaryKeyError);
  };
  const handleOpenModalForm = () => {
    setModalFormState(true);
  };
  const handleCloseModalForm = () => {
    setModalFormState(false);
  };

  return (
    <Box p={3}>
      <ModalForm
        isOpen={modalFormState}
        handleClose={handleCloseModalForm}
        title={
          currentFormAction === "add"
            ? t("visitsManager.modalAddTitle")
            : t("visitsManager.modalUpdateTitle")
        }
      >
        {modalFormState ? (
          <VisitForm
            visit={currentVisit}
            onAction={currentFormAction}
            onSubmit={handleOnSubmitForm}
          />
        ) : (
          <></>
        )}
      </ModalForm>
      <Typography variant="h4" gutterBottom>
        {t("visitsManager.title")}
      </Typography>

      <XVisitsTable
        visits={visits}
        onDelete={fetchDeleteVisit}
        onUpdate={handleUpdateVisit}
        onAdd={handleAddVisit}
        onUpdateVP={handleUpdateVP}
        onReload={loadVisits}
        loading={loading}
      />
    </Box>
  );
}
