import { httpService } from "./httpService";

export default class UserService {
  usersUrl(userId) {
    return `users/${userId || ""}`;
  }

  getUsers = async () => {
    return await httpService.get(this.usersUrl());
  };

  getUserById = async (userId) => {
    return await httpService.get(this.usersUrl(userId));
  };

  createUser = async (user) => {
    return await httpService.post(this.usersUrl(), user);
  };

  updateUser = async (userId, user) => {
    return await httpService.put(this.usersUrl(userId), user);
  };

  deleteUser = async (userId) => {
    return await httpService.delete(this.usersUrl(userId));
  };

  getUsersCount = async () => {
    return await httpService.get(this.usersUrl("data/count"));
  };
}

export const userService = new UserService();
