import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../Routes/paths";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  cover: {
    maxWidth: "100%",
    width: "100%",
    height: "50vh",
    //minHeight: "50vh",
    position: "relative",
    display: "block",
    objectFit: "cover",
  },
  inner: {
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    right: 0,
    padding: theme.spacing(2),
    backgroundColor: "#000000c9",
    margin: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.white,
    textShadow: "2px 2px #000000",
  },
  text: {
    fontWeight: 500,
    color: theme.palette.common.white,
    textShadow: "2px 2px #000000",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonDemo: {
    color: theme.palette.common.white,
    border: "1px solid #FFFFFF50",
    margin: theme.spacing(1),
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[900],
    },
  },
  buttonSign: {
    margin: theme.spacing(1),
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const RedirectedIFrame = ({ cover }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.root}>
      <img className={classes.cover} src={cover} alt="" />
      <div className={classes.inner}>
        <Typography
          variant="h5"
          color="textPrimary"
          gutterBottom
          className={classes.title}
        >
          {t("content.demoVisit.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.text}
        >
          {t("content.demoVisit.main")}
        </Typography>
        <div className={classes.buttons}>
          <Link
            component={Button}
            className={classes.buttonDemo}
            variant="outlined"
            to={paths.VISIT_DEMO}
          >
            {t("content.demoVisit.buttonDemo")}
          </Link>
          <Link
            component={Button}
            className={classes.buttonSign}
            variant="contained"
            color="primary"
            to={paths.SIGNUP}
          >
            {t("content.demoVisit.buttonSignup")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RedirectedIFrame;
