import React from "react";
import { useTranslation } from "react-i18next";
import { visitService } from "../../services/Visits/visitService";

import DashboardDeck from "./DashboardDeck";

const VisitsDeck = () => {
  const [t] = useTranslation();

  const fetchData = async () => {
    const result = await visitService.getVisitsCount();
    return result.data.map((row) => ({
      source: row.categoryId,
      value: row.total,
    }));
  };

  const rowWrapper = (catId, value) => {
    const cat = catId === "all" ? catId : `category${catId}`;
    return {
      source: t(`categories.${cat}`),
      value,
    };
  };

  return (
    <DashboardDeck
      title={t("adminDashboard.visits")}
      fetchData={fetchData}
      rowWrapper={rowWrapper}
      defaultData={[1, 2, 3, 4]}
    />
  );
};

export default VisitsDeck;
