import { Box, Tabs, Tab, TextField } from "@material-ui/core";
import React, { useState } from "react";
import SwitchItem from "../../Home/LayoutVisits/SwitchItem";
import { handleParticipantsInput } from "./LiveLinkGenerator";
import ParticipantTable from "./ParticipantTable";

const ParticipantsBox = ({ onChangeField, participants }) => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Participants" id={0} />
          <Tab label="Modifier" id={1} />
        </Tabs>
      </Box>
      <SwitchItem value={tab} route={0}>
        <ParticipantTable
          participants={handleParticipantsInput(participants)}
        />
      </SwitchItem>
      <SwitchItem value={tab} route={1}>
        <TextField
          id="input"
          multiline
          rows={10}
          defaultValue=""
          value={participants}
          variant="outlined"
          onChange={onChangeField}
          placeholder={`Nom prénom / émail / true ( true s'il est guide sinon laisser vide)`}
          fullWidth
        />
      </SwitchItem>
    </>
  );
};

export default ParticipantsBox;
