import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "../../services/userService";
import XUsersTable from "./XUsersTable";

export default function XUsersManager() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [t] = useTranslation();

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      setLoading(true);
      const result = await userService.getUsers();
      setUsers(result.data.users);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {t("usersManager.title")}
      </Typography>
      <XUsersTable users={users} onReload={loadUsers} loading={loading} />
    </Box>
  );
}
