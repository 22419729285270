export const paths = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  TWO_FACTOR: "/2fa",
  UNAUTHORIZED: "/401",
  NOT_FOUND: "/404",
  HOME: "/",
  MAP: "/map",
  HOME_VISITS: "/:visitId",
  MAP_VISITS: "/map/:visitId",
  VISIT_REGEX: "(visit-[\\w-]+)?",
  VISIT_DEMO: "/visit-demo",
  ME: "/me",
  USER: "/users/:userId",
  admin: {
    HOME: "/admin",
    VISITS: "/visits",
    VISITS_GEO: "/visitsGeo",
    USERS: "/users",
    DASHBOARD: "/dashboard",
    LIVE_LINK_GENERATOR: "/live-link-generator",
  },
};
